import moment from 'moment';
import { TOOLTIP_DATE_READABLE, TOOLTIP_DATETIME_READABLE } from '@constants';
import { getValue, parseNumberToSignificant } from '@helpers';

function isDayData(timestamp1, timestamp2) {
  const time1 = moment(timestamp1);
  const time2 = moment(timestamp2);

  // Calculate the difference in hours
  const diffInHours = Math.abs(time2.diff(time1, 'hours'));

  // Return true if more than 1 hour apart, otherwise false
  return diffInHours > 1;
}

export const getOrCreateTooltipDiv = (chart, theme, withButton) => {
  const {
    palette: {
      tooltip: { background, contrastText }
    }
  } = theme;
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.visibility = 'visible';
    tooltipEl.style.fontSize = '14px';
    tooltipEl.style.pointerEvents = withButton ? 'auto' : 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.textWrap = 'nowrap';
    tooltipEl.style.padding = '8px 16px';
    tooltipEl.style.minWidth = '300px';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const body = document.createElement('div');
    body.style.display = 'flex';
    body.style.flexDirection = 'column';
    body.style.gap = '8px';

    tooltipEl.appendChild(body);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }
  tooltipEl.style.background = background;

  tooltipEl.style.color = contrastText;

  return tooltipEl;
};

const showSyncTooltip = (chartRef, index) => {
  const chart = chartRef.current;
  if (!chart) return;
  const tooltip = chart.tooltip;
  const element = chart.getDatasetMeta(0).data[index];
  if (element) {
    tooltip.setActiveElements([{ datasetIndex: 0, index }], {
      x: element.x,
      y: element.y
    });
  }
};

export const createTooltipLabelText = (labelText) => {
  const label = document.createElement('span');
  label.textContent = `${labelText}:`;
  label.style.fontFamily = '"Noto Sans", Roboto, helvetica, Arial, sans-serif';
  label.style.fontSize = '0.75rem';
  label.style.lineHeight = '1.66';
  return label;
};

export const createTooltipValueText = (textValue) => {
  const text = document.createElement('p');
  text.textContent = textValue;
  text.style.fontFamily = '"Noto Sans", Roboto, helvetica, Arial, sans-serif';
  text.style.fontSize = '0.875rem';
  text.style.margin = '0';
  text.style.lineHeight = '1.43';
  return text;
};

export const defaultTooltip = (
  context,
  data,
  theme,
  unit,
  includeTooltipSum,
  hideEmptyValues,
  hideCurrentTooltip,
  syncRefs,
  handleLogClick = undefined
) => {
  if (!context) return;

  const {
    palette: {
      tooltip: { divider, contrastText }
    }
  } = theme;
  const { chart, tooltip } = context;

  const dataIndex = tooltip.dataPoints?.[0]?.dataIndex;
  if (hideCurrentTooltip) {
    syncRefs?.forEach((reference) => {
      showSyncTooltip(reference, dataIndex);
    });
    return;
  }

  const tooltipEl = getOrCreateTooltipDiv(chart, theme, !!handleLogClick);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    tooltipEl.style.visibility = 'hidden';
    return;
  }

  if (tooltip.body) {
    let title = tooltip.title || [];
    if (Array.isArray(tooltip)) {
      title = tooltip[0];
    }
    const header = document.createElement('div');
    header.style.fontWeight = 'bold';
    header.style.fontSize = '16px';

    let format = TOOLTIP_DATETIME_READABLE;
    if (isDayData(data.labels[0], data.labels[1])) {
      format = TOOLTIP_DATE_READABLE;
    }

    header.textContent = moment.utc(parseInt(title)).format(format);

    const tableBody = document.createElement('div');

    if (includeTooltipSum) {
      let sum = 0;
      data.datasets.forEach(({ data, hidden }) => {
        sum += hidden ? 0 : getValue(data[dataIndex].y);
      });

      // Check if sum is a valid number
      if (!isNaN(sum)) {
        const content = document.createElement('div');
        content.style.display = 'flex';
        content.style.justifyContent = 'space-between';
        content.style.gap = '16px';

        const dividerEl = document.createElement('div');
        dividerEl.style.width = `100%`;
        dividerEl.style.height = `1px`;
        dividerEl.style.paddingBottom = `8px`;
        dividerEl.style.borderTop = `1px solid ${divider}`;

        let valueText = createTooltipValueText(
          `${parseNumberToSignificant(parseFloat(sum))} ${unit}`
        );
        let sumText = createTooltipValueText(`Sum`);

        content.appendChild(sumText);
        content.appendChild(valueText);
        tableBody.appendChild(content);
        tableBody.appendChild(dividerEl);
      }
    }

    // Create content for each point and line
    data?.datasets?.forEach(
      ({ data, label, borderColor, type, hidden, backgroundColor, unit: hiddenUnit }) => {
        const content = document.createElement('div');
        content.style.display = 'flex';
        content.style.justifyContent = 'space-between';
        content.style.gap = '16px';

        const legend = document.createElement('div');
        legend.style.display = 'flex';
        legend.style.gap = '8px';
        legend.style.alignItems = 'center';
        legend.style.flexDirection = 'row';

        const legendBox = document.createElement('span');
        legendBox.style.background = type === 'bar' ? backgroundColor : borderColor;
        legendBox.style.height = '8px';
        legendBox.style.width = '8px';
        legendBox.style.display = 'inline-block';
        const title = createTooltipLabelText(label);

        let value = data[dataIndex].y;
        let valueText;

        if (value) {
          valueText = createTooltipValueText(
            `${parseNumberToSignificant(parseFloat(value))} ${hidden ? hiddenUnit : unit}`
          );
        } else {
          valueText = createTooltipValueText('-');
        }
        if (hideEmptyValues && !value) {
          return;
        } else {
          legend.appendChild(legendBox);
          legend.appendChild(title);
          content.appendChild(legend);
          content.appendChild(valueText);
          tableBody.appendChild(content);
        }
      }
    );

    if (handleLogClick) {
      // Add the button to the tooltip
      const buttonContainer = document.createElement('div');
      buttonContainer.style.display = 'flex';
      buttonContainer.style.width = '100%';
      buttonContainer.style.placeContent = 'center';
      buttonContainer.style.flexDirection = 'row';
      const button = document.createElement('button');
      button.textContent = 'SEE LOG';
      button.style.marginTop = '10px'; // Add spacing to separate from content
      button.style.padding = '8px 16px';
      button.style.border = '1px solid';
      button.style.borderColor = contrastText;
      button.style.background = 'transparent';
      button.style.borderRadius = '100px';
      button.style.color = contrastText;
      button.style.cursor = 'pointer';
      button.style.fontSize = '14px';
      button.style.minWidth = '63px'; // Make the button span the width of the tooltip
      button.style.textAlign = 'center';

      // Add event listener if handleLogClick is provided
      button.addEventListener('click', () => {
        handleLogClick(title[0]);
      });

      // Append the button to the tooltip
      buttonContainer.appendChild(button);
      tableBody.appendChild(buttonContainer);
    }

    const tableRoot = tooltipEl.querySelector('div');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(header);
    tableRoot.appendChild(tableBody);
  }

  // Positioning the tooltip
  let { offsetLeft: positionX } = chart.canvas;
  let left = positionX + tooltip.caretX;
  let halfWidth = chart.canvas.width / 2;

  if (left < halfWidth) {
    tooltipEl.style.left = `${left - 20 + tooltipEl.getBoundingClientRect().width}px`;
  } else {
    tooltipEl.style.left = `${left + 20 - tooltipEl.getBoundingClientRect().width}px`;
  }

  // tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.top = `-${tooltipEl.getBoundingClientRect().height / 2}px`;

  syncRefs?.forEach((reference) => {
    showSyncTooltip(reference, dataIndex);
  });

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.visibility = 'visible';
  tooltipEl.style.position = 'absolute'; // Ensure absolute positioning
  tooltipEl.style.zIndex = '2000'; // Ensure it stays on top
  const zoomFactor = window.devicePixelRatio; // e.g., 1 for 100% zoom, 0.8 for 80%, 1.25 for 125%, etc.
  const actualCanvasWidth = chart.canvas.width / zoomFactor;

  if (handleLogClick) {
    let tooltipBound = tooltipEl.getBoundingClientRect();
    let tooltipHalfWidth = tooltipBound.width / 2;
    if (tooltipHalfWidth > left) {
      tooltipEl.style.left = `${tooltipHalfWidth}px`;
    } else if (left + tooltipHalfWidth > actualCanvasWidth) {
      tooltipEl.style.left = `${actualCanvasWidth - tooltipHalfWidth}px`;
    } else {
      tooltipEl.style.left = `${left}px`;
    }

    tooltipEl.style.top = `-${tooltipBound.height / 2}px`;

    tooltipEl.addEventListener('mouseout', () => {
      tooltipEl.style.opacity = 0;
      tooltipEl.style.visibility = 'hidden';
    });

    tooltipEl.addEventListener('mouseover', () => {
      tooltipEl.style.visibility = 'visible';
      tooltipEl.style.opacity = 1;
    });
  }
};
