import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getPercentDiff, getValue } from '@helpers';
import { useDisplayUnit } from '@hooks';
import { Divider, Stack } from '@esgian/esgianui';
import KPIChangeDisplay from '@components/Display/KPIChangeDisplay/KPIChangeDisplay';

function EquipmentComparisonKpis({ mainData, baselineData, loading }) {
  const displayUnit = useSelector(getDisplayUnit);
  const { getKpiDisplayText, valueWithUnit } = useDisplayUnit();

  const getSumData = (unit) => {
    let key = `${unit}${displayUnit}`;

    let selectedRigData = Object.values(mainData[key]);
    let baseData = Object.values(baselineData[key]);
    selectedRigData = selectedRigData.reduce((a, b) => a + getValue(b), 0);
    baseData = baseData.reduce((a, b) => a + getValue(b), 0);
    return {
      ...valueWithUnit(selectedRigData - baseData, displayUnit, true),
      percent: getPercentDiff(selectedRigData, baseData),
      mainRaw: selectedRigData,
      baseRaw: baseData
    };
  };

  const kpis = useMemo(() => {
    if (!baselineData || !mainData) return {};
    let res = {
      hpu: {
        ...getSumData('HPU')
      },
      td: {
        ...getSumData('TD')
      },
      dw: {
        ...getSumData('DW')
      },
      mp: {
        ...getSumData('MP')
      }
    };
    let totalMain = Object.values(res).reduce((a, b) => a + getValue(b.mainRaw), 0);
    let totalBase = Object.values(res).reduce((a, b) => a + getValue(b.baseRaw), 0);
    res.total = {
      ...valueWithUnit(totalMain - totalBase, displayUnit, true),
      percent: getPercentDiff(totalMain, totalBase)
    };
    return res;
  }, [displayUnit, mainData, baselineData]);

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.total?.percent}
        value={kpis.total?.value ?? null}
        unit={kpis.total?.title}
        title={getKpiDisplayText('Total')}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.hpu?.percent}
        value={kpis.hpu?.value ?? null}
        unit={kpis.hpu?.title}
        title={`HPU`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.td?.percent}
        value={kpis.td?.value ?? null}
        unit={kpis.td?.title}
        title={`Topdrive`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.dw?.percent}
        value={kpis.dw?.value ?? null}
        unit={kpis.dw?.title}
        title={`Drawworks`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.mp?.percent}
        value={kpis.mp?.value ?? null}
        unit={kpis.mp?.title}
        title={`MudPumps`}
      />
    </Stack>
  );
}

EquipmentComparisonKpis.propTypes = {
  mainData: PropTypes.object,
  baselineData: PropTypes.object,
  loading: PropTypes.bool
};

EquipmentComparisonKpis.defaultProps = { mainData: {}, baselineData: {}, loading: false };

export default EquipmentComparisonKpis;
