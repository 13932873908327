/* eslint-disable no-prototype-builtins */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@esgian/esgianui';
import LogDrawer from '@components/Drawers/LogDrawer/LogDrawer';
import { PeriodicOverviewWithDetailsChart } from '@components/Charts';
import { useSeriesBuilder, useDisplayUnit, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getStackedBarChartMax, getUnixTimeStamp, getValue } from '@helpers';
import moment from 'moment-timezone';
import {
  API_ROLLUP,
  DATE_TIMEZONE_FORMAT,
  TIMESTAMP,
  UTC_TIMESTAMP,
  DATE_FORMAT
} from '@constants';
import { useQuery } from '@tanstack/react-query';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { fetchEquipmentData } from '@api/Rig';
import { DailyRegeneratedEnergyDrawworks } from '@components/SubSections/Equipment';

const keyToColor = {
  MP: 0,
  HPU: 1,
  TD: 2,
  DW: 3
};

function PeriodicOverviewDetails({ equipmentData, loading }) {
  const [selectedView, handleViewChange] = useState('hour');
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [logInfo, setLogInfo] = useState(null);
  const { getCanvasTimeSeriesData, getEquipmentDataFields } = useSeriesBuilder();
  const { getUnitTitle } = useDisplayUnit();
  const { theme } = useTheme();
  const displayUnit = useSelector(getDisplayUnit);
  const { selectedRig } = useSelector(getMainPageSlice);
  const { selectedTimeZone } = useTimezone();

  const {
    palette: {
      charts: { fourColorsStacked }
    }
  } = theme;

  const buildMainSeriesData = useCallback(
    (clarifyData) => {
      const equipmentData = getEquipmentDataFields(clarifyData, displayUnit);

      let tempSeries = [];
      Object.keys(equipmentData).forEach((key) => {
        const { defaultArray, convertedArray } = getCanvasTimeSeriesData(
          equipmentData[key],
          displayUnit
        );
        tempSeries.push({ label: key.toUpperCase(), defaultArray, convertedArray });
      });
      let maxTot = getStackedBarChartMax(
        tempSeries?.map(({ defaultArray }) => {
          return { data: defaultArray };
        })
      );

      const { title, converted } = getUnitTitle(maxTot);

      tempSeries = tempSeries
        ?.map(({ label, defaultArray, convertedArray }) => {
          return {
            borderSkipped: true,
            stack: 1,
            order: keyToColor[label],
            backgroundColor: fourColorsStacked[keyToColor[label]],
            label: label,
            type: 'bar',
            converted: converted,
            data: converted ? convertedArray : defaultArray
          };
        })
        .sort((a, b) => a.order - b.order);
      return {
        unit: title,
        series: tempSeries
      };
    },
    [displayUnit]
  );

  const { unit: primaryUnit, primarySeries } = useMemo(() => {
    if (!equipmentData) return {};
    const { clarifyData } = equipmentData;
    const { unit, series: primarySeries } = buildMainSeriesData(clarifyData);
    if (!selectedColumn) {
      setSelectedColumn({
        date: moment.utc(primarySeries[0].data[0].x).format(UTC_TIMESTAMP),
        index: 0,
        isLast: false
      });
    }
    return {
      unit: unit,
      primarySeries: primarySeries
    };
  }, [equipmentData, displayUnit]);

  const secondaryDataQuery = useQuery({
    queryKey: [
      'equipment',
      {
        selectedView,
        selectedColumn,
        selectedRig
      }
    ],
    enabled: !!selectedRig && !!selectedView,
    placeholderData: null,
    queryFn: ({ signal }) => {
      const rollup = selectedView === 'hour' ? API_ROLLUP.HOUR : API_ROLLUP.MIN;
      let sDate = moment(selectedColumn.date).startOf('day');
      let eDate = moment.tz(selectedColumn.date, selectedTimeZone).endOf('day');

      if (selectedColumn.index >= 1 && selectedView === 'hour') {
        sDate = sDate.subtract(1, 'days');
      }
      if (!selectedColumn.isLast && selectedView === 'hour') {
        eDate = eDate.add(1, 'days');
      }
      if (eDate.isAfter(moment.tz(selectedTimeZone.date))) {
        eDate = moment.tz(selectedTimeZone.date);
      }
      eDate = moment(eDate.format(TIMESTAMP));
      const startDate = sDate.format(DATE_TIMEZONE_FORMAT);
      const endDate = eDate.format(DATE_TIMEZONE_FORMAT);
      return fetchEquipmentData(signal, { selectedRig, startDate, endDate }, rollup)
        .then((result) => {
          const { MPTotP, HPURLtotP, DWtotP, TDtotP } = result.clarifyData;
          return { MP: MPTotP, HPU: HPURLtotP, DW: DWtotP, TD: TDtotP };
        })
        .catch(() => {
          return null;
        });
    }
  });

  const secondarySeries = useMemo(() => {
    if (!secondaryDataQuery.data) return [];
    let secData = secondaryDataQuery.data;

    return Object.keys(secData)
      .map((key) => {
        return {
          stack: 1,
          order: keyToColor[key],
          borderColor: fourColorsStacked[keyToColor[key]],
          label: key,
          type: 'line',
          spanGaps: false,
          data: Object.keys(secData[key]).map((date) => {
            return { x: getUnixTimeStamp(date), y: getValue(secData[key][date], false) };
          })
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [secondaryDataQuery, displayUnit]);

  const { sDate, eDate } = useMemo(() => {
    if (!selectedColumn?.date) return { sDate: null, eDate: null };
    let sDate = moment(selectedColumn?.date).startOf('day');
    let eDate = moment.tz(selectedColumn?.date, selectedTimeZone).endOf('day');

    if (selectedColumn.index >= 1) {
      sDate = sDate.subtract(1, 'days');
    }
    if (!selectedColumn.isLast) {
      eDate = eDate.add(1, 'days');
    }
    if (eDate.isAfter(moment.tz(selectedTimeZone.date))) {
      eDate = moment.tz(selectedTimeZone.date);
    }
    sDate = sDate.format(DATE_FORMAT);
    eDate = eDate.format(DATE_FORMAT);
    return { sDate, eDate };
  }, [selectedColumn]);

  return (
    <Paper sx={{ p: 2 }}>
      <PeriodicOverviewWithDetailsChart
        secondaryPrimaryProps={{
          setSelectedColumn: setSelectedColumn,
          loading: loading,
          equipmentData: equipmentData
        }}
        SecondaryPrimaryChart={DailyRegeneratedEnergyDrawworks}
        selectedColumn={{ ...selectedColumn, selectedDate: { sDate, eDate } } ?? {}}
        setSelectedColumn={setSelectedColumn}
        secondarySeries={secondarySeries}
        secondaryUnit={'kW'}
        handleLogClick={setLogInfo}
        detailsTitle={'Equipment power details'}
        detailsTitleTooltip={`Total power consumption per hour for each equipment. Based on the day selected in the graph above, it shows the equipment power consumption for the day before, the selected day and the day after. Choose “View by Min” to view the power consumption in more detail`}
        handleViewChange={handleViewChange}
        primaryUnit={primaryUnit}
        primarySeries={primarySeries}
        loading={loading}
        selectedView={selectedView}
        subTitle="Detailed equipment power consumption in selection"
        title="Equipment overview"
        titleTooltipText={`Total consumption or emissions per day for each equipment in the selected time period.
Clicking on one of the bars will reveal the details per hour for the day before, selected day and the next day in the Equipment details graph below.`}
      />
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Paper>
  );
}

PeriodicOverviewDetails.propTypes = {
  loading: PropTypes.bool,
  equipmentData: PropTypes.object
};

PeriodicOverviewDetails.defaultProps = {
  loading: false,
  equipmentData: null
};

export default PeriodicOverviewDetails;
