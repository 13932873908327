import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, TableCell, TableRow, Typography } from '@esgian/esgianui';
import moment from 'moment-timezone';
import { fetchWarningData } from '@api/Rig';
import { API_ROLLUP, WARNINGS_TYPE, TIMESTAMP } from '@constants';
import { useSelector } from 'react-redux';
import {
  EngineAverageChartsWrapper,
  WarningPowerDetailsChart
} from '@components/SubSections/Analytics';
import WarningInfoBoxes from '@components/Tables/FocusAreasTable/WarningInfoBoxes';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useTimezone } from '@hooks/useTimezone';
import { useQuery } from '@tanstack/react-query';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { getSideMenuOpen } from '@store/features';

const standardizeResponse = (d, type) => {
  const { clarifyData: data, numOfEngines } = d;
  if (type === WARNINGS_TYPE.TooManyHPU) {
    return {
      peakPower: data.HPUP_available || [],
      avgPowerProd: data.HPUP || [],
      totPowerAvailable: data.OptHPU_available || [],
      optPowerAvailable: data.OptHPUP || [],
      engOn: data.HPUr || [],
      optEngOn: data.HPU_req || [],
      numOfEngines: numOfEngines
    };
  }
  if (type === WARNINGS_TYPE.TooManyEng) {
    let obj = {
      peakPower: data.P_peak || [],
      avgPowerProd: data.P_produced || [],
      totPowerAvailable: data.P_available || [],
      optPowerAvailable: data.P_available_sim || [],
      engOn: data.EngOn || [],
      optEngOn: data.EngReq || [],
      numOfEngines: numOfEngines,
      EngLsim: data.EngLsim || [],
      OptRigSFOC: data.OptRigSFOC || []
    };
    for (let i = 1; i <= numOfEngines; i++) {
      obj[`Eng${i}Lavg`] = data[`Eng${i}Lavg`];
      obj[`Eng${i}SFOC`] = data[`Eng${i}SFOC`];
    }

    return obj;
  }
  return {};
};

function WarningDetailsView({ rowData, numColumns }) {
  const { selectedRig, startDate, endDate } = useSelector(getMainPageSlice);
  const sideMenuOpen = useSelector(getSideMenuOpen);
  const { calculateDatesWithPadding } = useTimezone();
  const powerChartRef = useRef(null);
  const onlineChartRef = useRef(null);
  const avgLoadChartRef = useRef(null);
  const avgSfocChartRef = useRef(null);

  const chartQuery = useQuery({
    queryKey: ['analytics', 'table', { row: rowData }],
    enabled: [WARNINGS_TYPE.TooManyHPU, WARNINGS_TYPE.TooManyEng].includes(rowData?.type),
    placeholderData: null,
    queryFn: ({ signal }) => {
      let focusAreaStartDate = moment
        .parseZone(rowData.startDate)
        .subtract(30, 'minute')
        .format(TIMESTAMP);

      let focusAreaEndDate = moment.parseZone(rowData.endDate).add(30, 'minute').format(TIMESTAMP);

      const { dateStart: sDate, dateEnd: eDate } = calculateDatesWithPadding(startDate, endDate, {
        value: 30,
        unit: 'minutes'
      });

      return fetchWarningData(
        signal,
        selectedRig,
        sDate,
        eDate,
        focusAreaStartDate,
        focusAreaEndDate,
        API_ROLLUP.MIN,
        rowData.type
      )
        .then((result) => standardizeResponse(result, rowData.type))
        .catch(() => null);
    }
  });

  return (
    <TableRow>
      <TableCell
        sx={{ background: ({ palette }) => `${palette.background.default} !important` }}
        colSpan={numColumns}>
        <Paper
          sx={{
            pb: 2,
            background: ({ palette }) => `${palette.background.default}`,
            maxWidth: sideMenuOpen ? 'calc(100vw - 348px)' : 'calc(100vw - 100px)'
          }}>
          <Stack spacing={2}>
            <TextWithTooltipIcon
              tooltipText={'Summary of the focus area saving potential.'}
              label={<Typography variant={'h6'}>Analytics event summary</Typography>}
            />
            <WarningInfoBoxes data={rowData} />
            {[WARNINGS_TYPE.TooManyHPU, WARNINGS_TYPE.TooManyEng].includes(rowData.type) && (
              <WarningPowerDetailsChart
                powerSyncRefs={[avgSfocChartRef, avgLoadChartRef, onlineChartRef]}
                onlineSyncRefs={[avgSfocChartRef, avgLoadChartRef, powerChartRef]}
                powerChartRef={powerChartRef}
                onlineChartRef={onlineChartRef}
                primaryTitle={
                  rowData.type === WARNINGS_TYPE.TooManyHPU ? (
                    <TextWithTooltipIcon
                      tooltipText={
                        'The HPU Power details graph shows how much HPU power was available and how much was actually consumed before, during and after the event. It also shows a suggested HPU power available and how much power would have been used if number of pumps had been reduced.'
                      }
                      label={<Typography variant={'h6'}>HPU power details</Typography>}
                    />
                  ) : (
                    <TextWithTooltipIcon
                      tooltipText={
                        'The Power details graph shows how much power was available to be consumed, how much was consumed and the power consumption peaks before, during and after the event. It also shows what the power available would be with less generators online.'
                      }
                      label={<Typography variant={'h6'}>Power details</Typography>}
                    />
                  )
                }
                secondaryTitle={
                  rowData.type === WARNINGS_TYPE.TooManyHPU ? (
                    <TextWithTooltipIcon
                      tooltipText={
                        'The HPU pumps online graph shows how many HPU Pumps was actually online before, during and after the event. It also shows a suggested number of HPU pumps required for the hydraulic consumption at the time of the event.'
                      }
                      label={<Typography variant={'h6'}>HPU pumps online</Typography>}
                    />
                  ) : (
                    <TextWithTooltipIcon
                      tooltipText={
                        'The Engines online graph shows how many engines were online before, during and after the event. It also shows how many engines would have been required to handle the power peaks and consumption during the event.'
                      }
                      label={<Typography variant={'h6'}>Engines online</Typography>}
                    />
                  )
                }
                unit={'kW'}
                detailsData={chartQuery.data}
                rowData={rowData}
                loading={chartQuery.isFetching}
              />
            )}
            {rowData.type === WARNINGS_TYPE.TooManyEng && (
              <EngineAverageChartsWrapper
                avgLoadSyncRefs={[avgSfocChartRef, powerChartRef, onlineChartRef]}
                avgSfocSyncRefs={[avgLoadChartRef, powerChartRef, onlineChartRef]}
                avgLoadChartRef={avgLoadChartRef}
                avgSfocChartRef={avgSfocChartRef}
                rowData={rowData}
                loading={chartQuery.isFetching}
                chartData={chartQuery.data}
              />
            )}
          </Stack>
        </Paper>
      </TableCell>
    </TableRow>
  );
}

WarningDetailsView.propTypes = {
  rowData: PropTypes.object,
  numColumns: PropTypes.number
};

WarningDetailsView.defaultProps = {
  rowData: null,
  numColumns: 8
};

export default WarningDetailsView;
