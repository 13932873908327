import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, Button, Paper, Stack, Typography } from '@esgian/esgianui';
import { DrillingEquipmentChart } from '@components/Charts';
import { SUB_SECTIONS } from '@constants';
import { useSeriesBuilder, useUpdateSection } from '@hooks';
import { useSelector } from 'react-redux';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { getDisplayUnit } from '@store/features';
import { DISPLAY_UNIT } from '@constants';

function DrillingEquipment({ loading, overviewData }) {
  const { updateMainPageSection } = useUpdateSection();
  const { getEquipmentDataSumSeries } = useSeriesBuilder();
  const displayUnit = useSelector(getDisplayUnit);

  const { series = [], unit = '' } = useMemo(() => {
    if (!overviewData) return {};
    const { clarifyData } = overviewData;
    const { title, mp, hpu, dw, td } = getEquipmentDataSumSeries(clarifyData, displayUnit);

    return { series: [{ data: [mp, hpu, dw, td] }], unit: title };
  }, [displayUnit, overviewData]);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Total CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Total NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Total fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Total energy consumption in period';
    }
  }, [displayUnit]);
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack>
        <TextWithTooltipIcon
          tooltipText={
            'Total consumption or emissions in the selected time period for each equipment.'
          }
          label={<Typography variant={'h6'}>Equipment</Typography>}
        />
        <Typography variant={'body2'}>{getSubTitle}</Typography>
      </Stack>
      <DrillingEquipmentChart loading={loading} series={series} primaryXAxisLabel={unit} />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          onClick={() => updateMainPageSection(SUB_SECTIONS.EQUIPMENT)}
          endIcon={<ArrowForwardIcon />}
          variant={'outlined'}>
          See equipment page
        </Button>
      </Stack>
    </Paper>
  );
}

DrillingEquipment.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

DrillingEquipment.defaultProps = {
  loading: false,
  overviewData: null
};

export default DrillingEquipment;
