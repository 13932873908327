import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack } from '@esgian/esgianui';
import KPIChangeDisplay from '@components/Display/KPIChangeDisplay/KPIChangeDisplay';
import { getPercentDiff, getValue } from '@helpers';

function PowerMgmtComparisonKpis({ mainData, baselineData, loading }) {
  const getAvgData = (data, key) => {
    let dataArray = Object.values(data?.clarifyData[key])?.filter((value) => value !== 'NaN');
    let sum = dataArray?.reduce((a, b) => a + getValue(b), 0);
    return { sum: sum, count: dataArray.length };
  };

  const sumObjects = (current, res) => {
    return { sum: current.sum + res.sum, count: current.count + res.count };
  };

  const kpis = useMemo(() => {
    if (!mainData || !baselineData) return {};
    let mainSfoc = { sum: 0, count: 0 };
    let baseSfoc = { sum: 0, count: 0 };
    let mainLavg = { sum: 0, count: 0 };
    let baseLavg = { sum: 0, count: 0 };

    let mainEngOn = getAvgData(mainData, 'EngOn');
    let baseEngOn = getAvgData(baselineData, 'EngOn');
    mainEngOn = mainEngOn.sum / mainEngOn.count;
    baseEngOn = baseEngOn.sum / baseEngOn.count;

    for (let i = 1; i < mainData.numOfEngines; i++) {
      mainSfoc = sumObjects(mainSfoc, getAvgData(mainData, `Eng${i}SFOC`));
      baseSfoc = sumObjects(baseSfoc, getAvgData(baselineData, `Eng${i}SFOC`));
      mainLavg = sumObjects(mainLavg, getAvgData(mainData, `Eng${i}Lavg`));
      baseLavg = sumObjects(baseLavg, getAvgData(baselineData, `Eng${i}Lavg`));
    }
    mainSfoc = mainSfoc.sum / mainSfoc.count;
    baseSfoc = baseSfoc.sum / baseSfoc.count;
    mainLavg = mainLavg.sum / mainLavg.count;
    baseLavg = baseLavg.sum / baseLavg.count;

    return {
      engOn: {
        value: mainEngOn - baseEngOn,
        percent: getPercentDiff(mainEngOn, baseEngOn)
      },
      sfoc: {
        value: mainSfoc - baseSfoc,
        percent: getPercentDiff(mainSfoc, baseSfoc)
      },
      load: {
        value: mainLavg - baseLavg,
        percent: getPercentDiff(mainLavg, baseLavg)
      }
    };
  }, [mainData, baselineData]);

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.sfoc?.percent}
        value={kpis.sfoc?.value ?? null}
        unit="g/kWh"
        title={`Engines avg SFOC`}
      />

      <KPIChangeDisplay
        invert
        loading={loading}
        percentChange={kpis.load?.percent}
        value={kpis.load?.value ?? null}
        unit="%"
        title={`Engines avg load`}
      />

      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.engOn?.percent}
        value={kpis.engOn?.value ?? null}
        title={`Avg engines online`}
      />
    </Stack>
  );
}

PowerMgmtComparisonKpis.propTypes = {
  mainData: PropTypes.object,
  baselineData: PropTypes.object,
  loading: PropTypes.bool
};

PowerMgmtComparisonKpis.defaultProps = { mainData: {}, baselineData: {}, loading: false };

export default PowerMgmtComparisonKpis;
