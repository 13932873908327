import React, { useMemo, useState } from 'react';
import { Divider, Paper, Stack, Typography } from '@esgian/esgianui';
import KpiChangeDisplay from '@components/Display/KPIChangeDisplay';
import { useSelector } from 'react-redux';
import { DISPLAY_UNIT } from '@constants';
import { getBaselineDurationHours } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { useBaselineQueries, useDisplayUnit } from '@hooks';
import { getPercentDiff, getValue } from '@helpers';
import { getMainDurationHours } from '@store/features/filters/MainPageSlice/MainPageSlice';

function BaselineKeyFigures() {
  const [loading, setLoading] = useState(true);
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();
  const mainDurationHours = useSelector(getMainDurationHours);
  const baselineDurationHours = useSelector(getBaselineDurationHours);
  const { baselineKeyFiguresQueries } = useBaselineQueries();

  const getResultSum = (results, duration) => {
    let res = {};
    Object.keys(results).forEach((key) => {
      let val = Object.values(results[key]).reduce((a, b) => a + getValue(b), 0);
      res[key] = val / duration;
    });

    return res;
  };

  const results = useMemo(() => {
    let mainData = baselineKeyFiguresQueries[0].data;
    let baselineData = baselineKeyFiguresQueries[1].data;
    if (!mainData || !baselineData) return {};
    mainData = getResultSum(mainData.clarifyData, mainDurationHours);
    baselineData = getResultSum(baselineData.clarifyData, baselineDurationHours);

    let fuel = mainData.RigFuel - baselineData.RigFuel;
    let co2 = mainData.RigCO2 - baselineData.RigCO2;

    const { title: co2Title, converted: co2Conv } = getUnitTitle(Math.abs(co2), DISPLAY_UNIT.CO2);
    const { title: fuelTitle, converted: fuelConv } = getUnitTitle(
      Math.abs(fuel),
      DISPLAY_UNIT.FUEL
    );
    co2 = co2Conv ? convertUnitValue(co2, DISPLAY_UNIT.CO2, false) : co2;
    fuel = fuelConv ? convertUnitValue(fuel, DISPLAY_UNIT.FUEL, false) : fuel;
    setLoading(false);
    // Need average over time
    return {
      co2: co2,
      co2Title: co2Title,
      co2Diff: getPercentDiff(mainData.RigCO2, baselineData.RigCO2),
      energy: mainData.RigEnergy - baselineData.RigEnergy,
      energyDiff: getPercentDiff(mainData.RigEnergy, baselineData.RigEnergy),
      fuelTitle: fuelTitle,
      fuel: fuel,
      fuelDiff: getPercentDiff(mainData.RigFuel, baselineData.RigFuel),
      nox: mainData.RigNOx - baselineData.RigNOx,
      noxDiff: getPercentDiff(mainData.RigNOx, baselineData.RigNOx)
    };
  }, [baselineKeyFiguresQueries[0].data, baselineKeyFiguresQueries[1].data]);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Typography variant={'h6'}>Key figures</Typography>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={'space-around'}
          divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
          <KpiChangeDisplay
            loading={loading}
            value={results.energy}
            unit="kWh"
            percentChange={results.energyDiff}
            title={`Average energy consumption/hour`}
          />
          <KpiChangeDisplay
            loading={loading}
            value={results.fuel}
            unit={results.fuelTitle}
            percentChange={results.fuelDiff}
            title="Average fuel consumption/hour"
          />
          <KpiChangeDisplay
            loading={loading}
            value={results.co2}
            unit={results.co2Title}
            percentChange={results.co2Diff}
            title={
              <Typography variant={'caption'} color={'text.disabled'}>
                Average CO<sub>2</sub>e emissions/hour
              </Typography>
            }
          />
          <KpiChangeDisplay
            loading={loading}
            value={results.nox}
            percentChange={results.noxDiff}
            unit="kg"
            title={
              <Typography variant={'caption'} color={'text.disabled'}>
                Average NO<sub>x</sub>emissions/hour
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

BaselineKeyFigures.propTypes = {};

BaselineKeyFigures.defaultProps = {};

export default BaselineKeyFigures;
