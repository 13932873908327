function hasBars(chart) {
  // Loop through all datasets in the chart
  const datasets = chart.data.datasets;

  // Check if any dataset has a 'bar' type and has data points
  for (let i = 0; i < datasets.length; i++) {
    if (chart.config.type === 'bar' || datasets[i].type === 'bar') {
      // Check if the dataset has non-empty data
      if (datasets[i].data && datasets[i].data.length > 0) {
        return true; // Chart has bars
      }
    }
  }

  return false; // No bars found
}

export const highlightPeriodPlugin = {
  id: 'highlightPeriod',
  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx, // Canvas context
      chartArea: { top, bottom }, // Chart area dimensions
      scales: { x } // X-axis scale (category)
    } = chart;

    const { startIndex, endIndex } = options;
    if (startIndex === null || endIndex === null) return;
    if (startIndex === -1 || endIndex === -1) return;

    // Get the width of each bar
    let barWidth = 0;
    if (hasBars(chart)) {
      barWidth = x.getPixelForValue(startIndex + 1) - x.getPixelForValue(startIndex) || 0;
      barWidth /= 2;
    }

    // Get pixel positions for the start and end indices
    let startX = x.getPixelForValue(startIndex) - barWidth;
    let endX = x.getPixelForValue(endIndex) + barWidth;

    if (startX === endX) return;

    // Save the canvas context state
    ctx.save();

    // Set the highlight color (adjust as needed)
    ctx.fillStyle = options.color || 'rgba(0, 0, 255, 0.2)';

    // Draw the highlight rectangle, covering the full bar width
    ctx.fillRect(startX, top, endX - startX, bottom - top);

    // Restore the canvas context to its previous state
    ctx.restore();
  }
};
