import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@esgian/esgianui';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { KeyFigureValueDisplay } from '@components';
import { useDisplayUnit } from '@hooks';
import { DISPLAY_UNIT } from '@constants';
import { getDollarAmountReadable } from '@helpers';

function PotentialSavingsSummary({ loading, savingsSummary }) {
  const { valueWithUnit } = useDisplayUnit();
  const { energySaving, fuelSaving, co2Saving, noxSaving, costSaving, runTimeSaving } =
    savingsSummary;
  return (
    <Stack>
      <TextWithTooltipIcon
        tooltipText={
          'Summary of all focus areas saving potential in the selected time period. Cost savings is the sum of fuel and maintenance cost.'
        }
        label={
          <Typography variant={'subtitle1'} bold>
            Potential savings summary
          </Typography>
        }
      />
      <Stack direction={'row'} spacing={4} sx={{ pt: 2 }}>
        <Stack spacing={1}>
          <KeyFigureValueDisplay
            loading={loading}
            unitTextColor={'text.secondary'}
            mainValueVariant={'subtitle1'}
            unitValueVariant={'caption'}
            boldMainValue
            mainValue={isNaN(runTimeSaving) ? '-' : `${parseInt(runTimeSaving) || 0} hours`}
            unitValue={'Potential engine hours saving'}
          />
          <KeyFigureValueDisplay
            loading={loading}
            unitTextColor={'text.secondary'}
            mainValueVariant={'subtitle1'}
            unitValueVariant={'caption'}
            boldMainValue
            mainValue={valueWithUnit(energySaving, DISPLAY_UNIT.ENERGY)}
            unitValue={'Potential energy saving'}
          />
          <KeyFigureValueDisplay
            loading={loading}
            mainValueVariant={'subtitle1'}
            boldMainValue
            mainValue={valueWithUnit(co2Saving, DISPLAY_UNIT.CO2)}
            unitValue={
              <Typography color={'text.secondary'} variant={'caption'}>
                Potential CO<sub>2</sub>e avoidance
              </Typography>
            }
          />
        </Stack>
        <Stack spacing={1}>
          <KeyFigureValueDisplay
            loading={loading}
            unitTextColor={'text.secondary'}
            mainValueVariant={'subtitle1'}
            unitValueVariant={'caption'}
            boldMainValue
            mainValue={isNaN(costSaving) ? '-' : `${getDollarAmountReadable(costSaving)} USD`}
            unitValue={'Potential cost savings'}
          />
          <KeyFigureValueDisplay
            loading={loading}
            unitTextColor={'text.secondary'}
            mainValueVariant={'subtitle1'}
            unitValueVariant={'caption'}
            boldMainValue
            mainValue={valueWithUnit(fuelSaving, DISPLAY_UNIT.FUEL)}
            unitValue={'Potential fuel saving'}
          />
          <KeyFigureValueDisplay
            loading={loading}
            mainValueVariant={'subtitle1'}
            boldMainValue
            mainValue={valueWithUnit(noxSaving, DISPLAY_UNIT.NOX)}
            unitValue={
              <Typography color={'text.secondary'} variant={'caption'}>
                Potential NO<sub>x</sub> avoidance
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

PotentialSavingsSummary.propTypes = {
  loading: PropTypes.bool,
  savingsSummary: PropTypes.object
};

PotentialSavingsSummary.defaultProps = {
  loading: false,
  savingsSummary: {}
};

export default PotentialSavingsSummary;
