import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import { KeyFigureValueDisplay } from '@components';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { useDisplayUnit } from '@hooks';
import { DISPLAY_UNIT } from '@constants';

function RigKeyFigures({ loading, overviewKeyFiguresData }) {
  const { valueWithUnit } = useDisplayUnit();
  const getUnitValues = useCallback((values) => {
    let tot = 0,
      avg,
      max = 0;

    values.forEach((val) => {
      const v = parseFloat(val);
      tot += v || 0;
      max = v > max ? v : max;
    });
    avg = tot / values?.length || 0;
    return { tot: tot, avg: avg, max: max };
  }, []);

  const data = useMemo(() => {
    if (!overviewKeyFiguresData || !Object.keys(overviewKeyFiguresData.clarifyData)?.length)
      return {};
    const { RigCO2, RigEnergy, RigFuel, RigNOx } = overviewKeyFiguresData.clarifyData;

    const { tot: totCo2, max: maxCo2, avg: avgCo2 } = getUnitValues(Object.values(RigCO2));
    const {
      tot: totEnergy,
      max: maxEnergy,
      avg: avgEnergy
    } = getUnitValues(Object.values(RigEnergy));
    const { tot: totFuel, max: maxFuel, avg: avgFuel } = getUnitValues(Object.values(RigFuel));
    const { tot: totNox, max: maxNox, avg: avgNox } = getUnitValues(Object.values(RigNOx));

    return {
      totEnergy: totEnergy,
      maxEnergy: maxEnergy,
      avgEnergy: avgEnergy,
      totCo2: totCo2,
      maxCo2: maxCo2,
      avgCo2: avgCo2,
      totFuel: totFuel,
      maxFuel: maxFuel,
      avgFuel: avgFuel,
      totNox: totNox,
      maxNox: maxNox,
      avgNox: avgNox
    };
  }, [overviewKeyFiguresData]);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Grid container>
        <Grid item xs={12} sx={{ pb: 3 }}>
          <TextWithTooltipIcon
            tooltipText={'Key figures for the selected time period.'}
            label={<Typography variant={'h6'}>Key figures</Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Stack sx={{ textAlign: 'center' }} spacing={1}>
              <Typography color={'text.secondary'} variant={'caption'}>
                TOTAL
              </Typography>
              <Stack spacing={2}>
                <KeyFigureValueDisplay
                  loading={loading}
                  mainValue={valueWithUnit(data.totEnergy, DISPLAY_UNIT.ENERGY)}
                  unitValue={'Energy'}
                />
                <KeyFigureValueDisplay
                  loading={loading}
                  mainValue={valueWithUnit(data.totFuel, DISPLAY_UNIT.FUEL)}
                  unitValue={'Fuel'}
                />
                <KeyFigureValueDisplay
                  loading={loading}
                  mainValue={valueWithUnit(data.totCo2, DISPLAY_UNIT.CO2)}
                  unitValue={
                    <Typography variant={'body2'}>
                      CO<sub>2</sub>e
                    </Typography>
                  }
                />
                <KeyFigureValueDisplay
                  loading={loading}
                  mainValue={valueWithUnit(data.totNox, DISPLAY_UNIT.NOX)}
                  unitValue={
                    <Typography variant={'body2'}>
                      NO<sub>x</sub>
                    </Typography>
                  }
                />
              </Stack>
            </Stack>

            <Stack direction={'row'} justifyContent={'space-evenly'}>
              <Stack sx={{ textAlign: 'center' }} spacing={1}>
                <Typography color={'text.secondary'} variant={'caption'}>
                  MAX/HOUR
                </Typography>
                <Stack spacing={2}>
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.maxEnergy, DISPLAY_UNIT.ENERGY)}
                    unitValue={'Max Energy/hour'}
                  />
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.maxFuel, DISPLAY_UNIT.FUEL)}
                    unitValue={'Max Fuel/hour'}
                  />
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.maxCo2, DISPLAY_UNIT.CO2)}
                    unitValue={
                      <Typography variant={'body2'}>
                        Max CO<sub>2</sub>e/hour
                      </Typography>
                    }
                  />
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.maxNox, DISPLAY_UNIT.NOX)}
                    unitValue={
                      <Typography variant={'body2'}>
                        Max NO<sub>x</sub>/hour
                      </Typography>
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-evenly'}>
              <Stack sx={{ textAlign: 'center' }} spacing={1}>
                <Typography color={'text.secondary'} variant={'caption'}>
                  AVERAGE/HOUR
                </Typography>
                <Stack spacing={2}>
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.avgEnergy, DISPLAY_UNIT.ENERGY)}
                    unitValue={'Avg Energy/hour'}
                  />
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.avgFuel, DISPLAY_UNIT.FUEL)}
                    unitValue={'Avg. Fuel/hour'}
                  />
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.avgCo2, DISPLAY_UNIT.CO2)}
                    unitValue={
                      <Typography variant={'body2'}>
                        Avg. CO<sub>2</sub>e/hour
                      </Typography>
                    }
                  />
                  <KeyFigureValueDisplay
                    loading={loading}
                    mainValue={valueWithUnit(data.avgNox, DISPLAY_UNIT.NOX)}
                    unitValue={
                      <Typography variant={'body2'}>
                        Avg. NO<sub>x</sub>/hour
                      </Typography>
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

RigKeyFigures.propTypes = {
  loading: PropTypes.bool,
  overviewKeyFiguresData: PropTypes.object
};

RigKeyFigures.defaultProps = {
  loading: false,
  overviewKeyFiguresData: null
};

export default RigKeyFigures;
