import React from 'react';
import PropTypes from 'prop-types';
import { Box, HelpOutlineIcon, ToolTip, Typography } from '@esgian/esgianui';

function TextWithTooltipIcon({ gap, iconSize, labelVariant, label, tooltipText, styles }) {
  return (
    <Box sx={{ display: 'flex', gap: gap, alignItems: 'center', ...styles }}>
      {typeof label === 'string' || label instanceof String ? (
        <Typography variant={labelVariant}> {label}</Typography>
      ) : (
        label
      )}
      <ToolTip title={tooltipText}>
        <HelpOutlineIcon sx={{ fontSize: iconSize }} />
      </ToolTip>
    </Box>
  );
}

TextWithTooltipIcon.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  iconSize: PropTypes.string,
  labelVariant: PropTypes.string,
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.object
};

TextWithTooltipIcon.defaultProps = {
  labelVariant: 'subtitle2',
  iconSize: '16px',
  gap: 1
};

export default TextWithTooltipIcon;
