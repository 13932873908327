import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon, Button, Paper, Stack, Typography, Box } from '@esgian/esgianui';
import { DISPLAY_UNIT, SUB_SECTIONS } from '@constants';
import { useUpdateSection, useDisplayUnit, useSeriesBuilder, useTheme } from '@hooks';
import { OperationsPeriodicOverviewChart } from '@components/Charts';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { getUnixTimeStamp } from '@helpers';

function OperationsPeriodicOverview({ loading, overviewData }) {
  const { updateMainPageSection } = useUpdateSection();
  const { getUnitTitle } = useDisplayUnit();
  const { getSeriesData } = useSeriesBuilder();
  const displayUnit = useSelector(getDisplayUnit);
  const { theme } = useTheme();
  const {
    palette: {
      charts: { twoColorsSameType }
    }
  } = theme;
  const { categories, series, title } = useMemo(() => {
    if (!overviewData) return {};
    const {
      clarifyData: {
        AccomEnergy,
        AccomCO2,
        AccomFuel,
        AccomNOx,
        DrillingEnergy,
        DrillingCO2,
        DrillingFuel,
        DrillingNOx
      }
    } = overviewData;
    let accommodation = AccomCO2;
    let drilling = DrillingCO2;

    if (displayUnit === DISPLAY_UNIT.ENERGY) {
      accommodation = AccomEnergy;
      drilling = DrillingEnergy;
    }
    if (displayUnit === DISPLAY_UNIT.FUEL) {
      accommodation = AccomFuel;
      drilling = DrillingFuel;
    }
    if (displayUnit === DISPLAY_UNIT.NOX) {
      accommodation = AccomNOx;
      drilling = DrillingNOx;
    }
    const {
      max: maxAccom,
      defaultArray: defaultAccom,
      convertedArray: convertedAccom
    } = getSeriesData(accommodation, displayUnit);
    const {
      max: maxDrilling,
      defaultArray: defaultDrilling,
      convertedArray: convertedDrilling
    } = getSeriesData(drilling, displayUnit);

    let max = maxAccom > maxDrilling ? maxAccom : maxDrilling;
    const { title, converted } = getUnitTitle(max);
    let dates = Object.keys(accommodation).map((key) => getUnixTimeStamp(key));

    return {
      title: title,
      series: [
        {
          label: 'Hotel and utilities',
          type: 'bar',
          borderColor: twoColorsSameType[0],
          backgroundColor: twoColorsSameType[0],
          borderSkipped: true,
          stack: 1,
          data: [...(converted ? convertedAccom : defaultAccom)].map((val, i) => {
            return { x: dates[i], y: val };
          })
        },
        {
          label: 'Drilling equipment',
          type: 'bar',
          stack: 1,
          borderColor: twoColorsSameType[1],
          backgroundColor: twoColorsSameType[1],
          borderSkipped: true,
          data: [...(converted ? convertedDrilling : defaultDrilling)].map((val, i) => {
            return { x: dates[i], y: val };
          })
        }
      ]
    };
  }, [overviewData, displayUnit, theme]);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Daily CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Daily NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Daily fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Daily energy consumption in period';
    }
  }, [displayUnit]);

  return (
    <Paper sx={{ p: 2, minHeight: '100%' }}>
      <Stack spacing={2}>
        <Box>
          <TextWithTooltipIcon
            tooltipText={
              'Total consumption or emissions in the selected time period for each system category shown per day.'
            }
            label={<Typography variant={'h6'}>System overview</Typography>}
          />
          <Typography variant="body2">{getSubTitle}</Typography>
        </Box>
        <OperationsPeriodicOverviewChart
          unitTitle={title}
          loading={loading}
          categories={categories}
          series={series}
        />
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Button
            onClick={() => updateMainPageSection(SUB_SECTIONS.DRILLING_ACTIVITIES)}
            endIcon={<ArrowForwardIcon />}
            variant={'outlined'}>
            See Drilling activities page
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}

OperationsPeriodicOverview.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

OperationsPeriodicOverview.defaultProps = {
  overviewData: null,
  loading: false
};

export default OperationsPeriodicOverview;
