import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ApexChart,
  Button,
  CloseIcon,
  DatePickerMobi,
  DeleteOutlineIcon,
  IconButton,
  Modal,
  PlusIcon,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Autocomplete
} from '@esgian/esgianui';
import { getRigFuelPrice } from '@api/Settings';
import moment from 'moment-timezone';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { TIME_ZONE } from '@constants';
import {
  getMainPageRig,
  getTimeZone,
  getCustomUTC,
  getRigUTC
} from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useQuery } from '@tanstack/react-query';

const getOptions = (theme, categories) => {
  const {
    palette: {
      mode,
      charts: { background, primaryChartColor }
    }
  } = theme;
  return {
    chart: {
      animations: {
        enabled: false
      },
      type: 'area',
      background: background,
      toolbar: {
        show: false
      }
    },
    stroke: {
      show: true,
      curve: 'stepline',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0
    },
    grid: {
      show: true,
      padding: { top: 0, bottom: -20 }
    },
    colors: [primaryChartColor],
    theme: {
      mode: mode
    },
    fill: {
      opacity: 0.5,
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.5,
        opacityTo: 0.5
      }
    },
    plotOptions: {
      bar: { columnWidth: '100%' }
    },
    dataLabels: {
      enabled: false
    },

    yaxis: {
      min: 0,
      labels: {
        maxWidth: 30
      },
      max: (max) => max * 1.2,
      tickAmount: 5,
      decimalsInFloat: 0
    },
    legend: {
      show: false
    },

    xaxis: {
      axisTicks: {
        show: false
      },
      tickPlacement: 'on',
      categories: categories,
      labels: {
        style: {
          maxWidth: 20,
          fontSize: '10px'
        },
        show: true,
        rotate: -25,
        rotateAlways: true,
        hideOverlappingLabels: false
      }
    }
  };
};
function RigSettingsModal({ handleClose, handleSave }) {
  const timeZone = useSelector(getTimeZone);
  const selectedUTC = useSelector(getCustomUTC);
  const { customScrollbar } = useTheme();
  const [tempFuelPrices, setTempFuelPrices] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(timeZone);
  const [selectedCustomUTC, setSelectedCustomUTC] = useState(selectedUTC);
  const { theme } = useTheme();
  const selectedRig = useSelector(getMainPageRig);
  const rigUTC = useSelector(getRigUTC);

  const timeZones = moment.tz.names();

  useEffect(() => {
    setSelectedTimeZone(timeZone);
    setSelectedCustomUTC(selectedUTC);
  }, [timeZone, selectedUTC]);

  const fuelPricesQuery = useQuery({
    queryKey: ['fuelprice', { selectedRig: selectedRig }],
    enabled: !!selectedRig,
    placeholderData: [],
    queryFn: ({ queryKey, signal }) => {
      return getRigFuelPrice(signal, queryKey[1])
        .then((result) => result.reverse())
        .catch(() => []);
    }
  });

  useEffect(() => {
    setTempFuelPrices(structuredClone(fuelPricesQuery.data));
  }, [fuelPricesQuery.data]);

  const handleFuelChange = (val, i) => {
    let fuel = [...tempFuelPrices];
    fuel[i].price = parseInt(val);
    setTempFuelPrices(fuel);
  };
  const handleStartChange = (val, i) => {
    let fuel = [...tempFuelPrices];
    fuel[i].startDate = val.value.format('YYYY-MM-DDTHH:mm:ss[Z]');
    setTempFuelPrices(fuel);
  };

  const { fuelSeries, fuelCategories } = useMemo(() => {
    let cat = [];
    let prices = [];
    let seriesArray = JSON.parse(JSON.stringify(tempFuelPrices));

    seriesArray.sort(
      (a, b) => moment.utc(a.startDate).valueOf() - moment.utc(b.startDate).valueOf()
    );

    seriesArray?.forEach(({ startDate, price }, i) => {
      cat.push(moment.utc(startDate).format('MMM/DD/YY'));
      prices.push(price);
      if (i === seriesArray.length - 1) {
        cat.push('Today');
        prices.push(price);
      }
    });

    let data = [{ name: '', data: prices }];
    return { fuelCategories: cat, fuelSeries: data };
  }, [tempFuelPrices]);

  const fuelChartOptions = useMemo(() => {
    return getOptions(theme, fuelCategories);
  }, [fuelCategories, theme]);

  const getDateString = (startDate, i) => {
    let start = moment.utc(startDate).format('MMM/DD/YYYY');
    if (i === 0 || tempFuelPrices[i - 1]?.isNew) {
      return `${start} - Today`;
    }
    let end = moment.utc(tempFuelPrices[i - 1].startDate).format('MMM/DD/YYYY');
    return `${start} - ${end}`;
  };

  const utcOffset = useMemo(() => {
    const momentTimezoneOffset = moment()
      .tz(rigUTC || 'Europe/Oslo')
      .format('Z');
    const formattedUTCOffset = `UTC ${momentTimezoneOffset}`;
    return `Rigs location (${formattedUTCOffset})`;
  }, [selectedRig, rigUTC]);

  return (
    <Modal
      closeIcon={<CloseIcon fontSize={'small'} />}
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.neutral.neutral01
        }
      }}
      size={'xs'}
      open
      DialogActionsProps={{ sx: { justifyContent: 'center' } }}
      handleClose={handleClose}
      actions={[
        <Button
          sx={{
            color: (theme) => theme.palette.secondary.contrastText
          }}
          key={'cancel-button'}
          onClick={handleClose}
          variant={'text'}>
          Cancel
        </Button>,
        <Button
          sx={{
            color: (theme) => theme.palette.secondary.contrastText,
            borderColor: (theme) => theme.palette.secondary.contrastText
          }}
          key={'save-button'}
          disabled={fuelPricesQuery.isFetching}
          onClick={() => {
            handleSave(
              deleteItems,
              tempFuelPrices,
              fuelPricesQuery.data,
              selectedTimeZone,
              selectedCustomUTC
            );
          }}
          variant={'contained'}>
          Save
        </Button>
      ]}
      title={'Settings'}>
      <Stack>
        <Stack spacing={1} direction={'column'} sx={{ mb: 5, mt: 2.5 }}>
          <Typography variant={'subtitle1'}>Time display</Typography>
          <RadioGroup
            value={selectedTimeZone}
            onChange={({ target }) => setSelectedTimeZone(target.value)}>
            <FormControlLabel
              value={TIME_ZONE.RIGS_LOCATION}
              control={<Radio size={'small'} />}
              label={<Typography variant={'body2'}>{utcOffset}</Typography>}
            />
            <FormControlLabel
              value={TIME_ZONE.CUSTOM_TIME_ZONE}
              control={<Radio size={'small'} />}
              label={<Typography variant={'body2'}>Custom time zone</Typography>}
            />
          </RadioGroup>
          {selectedTimeZone === TIME_ZONE.CUSTOM_TIME_ZONE && (
            <FormControl sx={{ width: '100%', pl: 3 }} size={'small'}>
              <Autocomplete
                ListboxProps={{ sx: { ...customScrollbar } }}
                disablePortal
                id="combo-box-demo"
                options={timeZones.map((timezone) => {
                  const offset = moment.tz(timezone).format('Z');
                  return { value: timezone, label: `${timezone} (UTC${offset})` };
                })}
                renderTags={() => {
                  return (
                    <Typography
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                      }}
                      variant={'caption'}>
                      asd
                    </Typography>
                  );
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                        height: '39px'
                      }
                    }}
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        height: '0px',
                        caretColor: 'transparent',
                        cursor: 'pointer'
                      }
                    }}
                  />
                )}
                onChange={(event, value) => setSelectedCustomUTC(value)}
                value={selectedCustomUTC}
                clearOnEscape={false}
                disableClearable={true}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value?.value;
                }}
              />
            </FormControl>
          )}
        </Stack>
        <Typography variant={'subtitle1'}>Fuel price per ton (USD)</Typography>
        <ApexChart
          newLoadingAnimation
          width={'100%'}
          loading={!fuelChartOptions || fuelPricesQuery.isFetching}
          height={180}
          options={fuelChartOptions || {}}
          type={'area'}
          data={fuelSeries}
        />
        <Stack
          onClick={() =>
            setTempFuelPrices([{ isNew: true, startDate: null, price: 0 }, ...tempFuelPrices])
          }
          spacing={1}
          direction={'row'}
          sx={{ pt: 2, pb: 2, cursor: 'pointer' }}>
          <PlusIcon
            sx={{ fill: ({ palette }) => palette.primary.main }}
            fontSize={'small'}
            color={'primary'}
          />
          <Typography color={'primary'} variant={'body2'}>
            Add new
          </Typography>
        </Stack>
        <Stack spacing={1}>
          {tempFuelPrices.map(({ isNew, price, startDate }, i) => (
            <Stack key={`fuel-price-${i}`} direction={'row'} justifyContent={'space-between'}>
              {!isNew ? (
                <Typography alignSelf={'center'} variant={'body2'}>
                  {getDateString(startDate, i)}
                </Typography>
              ) : (
                <DatePickerMobi
                  disableFuture
                  onClose={(val) => handleStartChange(val, i)}
                  selectedDate={
                    tempFuelPrices[i]?.startDate ? moment.utc(tempFuelPrices[i]?.startDate) : null
                  }
                  sx={{
                    inputProps: {
                      InputProps: {
                        sx: { fontSize: '0.875rem' }
                      },
                      defaultValue: undefined,
                      value: tempFuelPrices[i].startDate
                        ? moment.utc(tempFuelPrices[i].startDate).format('MMM/DD/YYYY')
                        : '',
                      placeholder: 'Start date',
                      variant: 'standard',
                      style: { fontSize: '14px !important' },
                      sx: { fontSize: '14px !important', width: '8em' }
                    }
                  }}
                />
              )}
              <Stack direction={'row'}>
                <TextField
                  inputProps={{ sx: { fontSize: '14px' } }}
                  value={price}
                  onChange={({ target }) => {
                    handleFuelChange(target.value, i);
                  }}
                  className={'settings-number-input'}
                  sx={{ textAlignLast: 'end', width: '50px' }}
                  size={'small'}
                  variant={'standard'}
                />
                <IconButton
                  onClick={() => {
                    if (isNew) {
                      setTempFuelPrices([...tempFuelPrices].filter((val, index) => index !== i));
                    } else {
                      setDeleteItems([...deleteItems, { ...tempFuelPrices[i] }]);
                      setTempFuelPrices([...tempFuelPrices].filter((val, index) => index !== i));
                    }
                  }}
                  size={'small'}>
                  <DeleteOutlineIcon sx={{ height: '16px' }} fontSize={'small'} />{' '}
                </IconButton>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
}

RigSettingsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

RigSettingsModal.defaultProps = {};

export default RigSettingsModal;
