import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { Link, Paper, Table } from '@esgian/esgianui';
import { API_ROLLUP, APP_NAME, DATE_TIMEZONE_FORMAT, DISPLAY_UNIT } from '@constants';
import { getLookupRigs } from '@store/features';
import { useDisplayUnit, useTheme } from '@hooks';
import { fetchOverviewKeyFiguresDataV2 } from '@api/Rig';
import { getInsightsRange } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { useQuery } from '@tanstack/react-query';
import { getValue } from '@helpers';

function sumAndFormatData(data) {
  let result = [];

  for (let rigId in data) {
    let clarifyData = data?.[rigId]?.clarifyData;
    let formattedData = { rigId: parseInt(rigId) };

    for (let key in clarifyData) {
      let sum = 0;
      for (let date in clarifyData?.[key]) {
        sum += getValue(clarifyData?.[key]?.[date]);
      }
      formattedData[key] = parseFloat(sum);
    }

    result.push(formattedData);
  }

  return result;
}

function mergeData(summedData, lookupRigs) {
  return lookupRigs?.map((lookupItem) => {
    const summedItem = summedData?.find((item) => item?.rigId === lookupItem?.rigId);
    if (summedItem) {
      return { ...lookupItem, ...summedItem };
    } else {
      return {
        ...lookupItem,
        RigCO2: '-',
        RigEnergy: '-',
        RigFuel: '-',
        RigNOx: '-'
      };
    }
  });
}
const sortCompareNumber = (order) => {
  return (obj1, obj2) => {
    const data1 = obj1.data;
    const data2 = obj2.data;

    // Handle the "-" case: always place "-" last
    if (data1 === '-' && data2 === '-') {
      return 0; // Both are "-"
    } else if (data1 === '-') {
      return 1; // obj1 should come last
    } else if (data2 === '-') {
      return -1; // obj2 should come last
    }

    // Assuming data1 and data2 are numeric after handling "-"
    const num1 = parseFloat(data1);
    const num2 = parseFloat(data2);

    // Standard numerical comparison
    return (num1 - num2) * (order === 'asc' ? 1 : -1);
  };
};

function RigsOverviewTable() {
  const lookupRigs = useSelector(getLookupRigs);
  const selectedRange = useSelector(getInsightsRange);
  const { valueWithUnit } = useDisplayUnit();

  const {
    theme: {
      palette: { mode }
    }
  } = useTheme();

  const tableDataQuery = useQuery({
    queryKey: ['dashboard', 'overview', { selectedRange }],
    enabled: !!selectedRange,
    placeholderData: null,
    queryFn: ({ signal }) => {
      let startOf = 'day';
      if (selectedRange.unit === 'days' && selectedRange.number === 1) {
        startOf = 'hour';
      }
      const endDate = moment.utc().startOf(startOf);
      const startDate = endDate.clone().subtract(selectedRange?.number, selectedRange?.unit);
      return fetchOverviewKeyFiguresDataV2(
        signal,
        {
          selectedRig: { rigName: '', rigId: 0 },
          startDate: startDate.format(DATE_TIMEZONE_FORMAT),
          endDate: endDate.format(DATE_TIMEZONE_FORMAT)
        },
        API_ROLLUP.DAY
      )
        .then((result) => result)
        .catch((e) => {
          console.log(e);
          return [];
        });
    }
  });

  const analyticsData = useMemo(() => {
    if (tableDataQuery.isSuccess && !!tableDataQuery.data) {
      const summedData = sumAndFormatData(tableDataQuery.data);
      return mergeData(summedData, lookupRigs);
    }
    return [];
  }, [tableDataQuery, lookupRigs]);

  const columns = useMemo(() => {
    return [
      {
        name: 'rigName',
        label: 'Rig name',
        options: {
          sort: true
        }
      },
      {
        name: 'location',
        label: 'Location',
        options: {
          sort: true,
          sortThirdClickReset: true
        }
      },
      {
        name: 'isTracked',
        label: 'GP data',
        options: {
          customBodyRender: (value) => {
            if (value) {
              return 'Active';
            }
            return 'Disconnected';
          }
        }
      },
      {
        name: 'RigEnergy',
        label: 'Energy',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return valueWithUnit(value || 0, DISPLAY_UNIT.ENERGY);
          },
          sortCompare: sortCompareNumber
        }
      },
      {
        name: 'RigFuel',
        label: 'Fuel',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return valueWithUnit(value || 0, DISPLAY_UNIT.FUEL);
          },
          sortCompare: sortCompareNumber
        }
      },
      {
        name: 'RigCO2',
        options: {
          customHeadLabelRender: () => (
            <>
              CO<sub>2</sub>e
            </>
          ),
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return valueWithUnit(value || 0, DISPLAY_UNIT.CO2);
          },
          sortCompare: sortCompareNumber
        }
      },
      {
        name: 'RigNOx',
        options: {
          customHeadLabelRender: () => (
            <>
              NO<sub>x</sub>
            </>
          ),

          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (value) => {
            return valueWithUnit(value || 0, DISPLAY_UNIT.NOX);
          },
          sortCompare: sortCompareNumber
        }
      },
      {
        name: 'rigId',
        label: 'Detail',
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const isTracked = tableMeta?.rowData[2];

            return isTracked ? (
              <Link href={`/gpr-live/rig?rig=${value}`}>View</Link>
            ) : (
              <span style={{ color: 'gray', cursor: 'not-allowed' }}>View</span>
            );
          }
        }
      }
    ];
  }, [tableDataQuery, selectedRange]);

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      search: true,
      download: true,
      downloadOptions: {
        filename: 'rigs-overview'
      },
      sortOrder: {
        name: 'isTracked',
        direction: 'desc'
      },
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      print: false,
      viewColumns: false,
      filter: false,
      pagination: true,
      elevation: 0,
      toolbar: true,
      rowsPerPageOptions: [10, 25, 50, 100]
    };
  }, []);

  return (
    <Paper id={'rigs-overview-table-test'}>
      <Table
        customStyle={{}}
        title={'Rigs overview'}
        mode={mode}
        data={analyticsData}
        columns={[...columns]}
        options={options}
        app={APP_NAME}
        loading={tableDataQuery.isFetching}
      />
    </Paper>
  );
}

RigsOverviewTable.propTypes = {};

RigsOverviewTable.defaultProps = {};

export default RigsOverviewTable;
