import React, { useEffect, useState } from 'react';
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom';
import PrivateRoute from '@components/PrivateRoute';
import { BaselineComparisonPage, DashboardPage, GreenpactLivePage, LoginPage } from '@pages';
import { APP_NAME, FEEDBACK_EMAIL, MAPBOX_API_TOKEN } from '@constants';
import mapboxgl from 'mapbox-gl';
import { localeEn, setOptions } from '@mobiscroll/react';
import './mobiscrollTheme.sass';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchUserFromToken,
  getUser,
  getUserStatus,
  getSideMenuOpen,
  getThemeMode,
  setSideMenuOpen,
  setThemeMode,
  getDropDownMenuOpen,
  reset_user
} from '@store/features';
import { getToken, logout } from '@store/helpers';
import { getSideMenuList } from './SideMenuList';
import { logoutUser } from '@api';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useTheme } from '@hooks';
import { Dashboard, EsgianTheme } from '@esgian/esgianui';
import { resetMainSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { resetBaselineSlice } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import TestPage from '@pages/TestPage';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

setOptions({
  locale: localeEn,
  theme: 'ios',
  themeVariant: 'light'
});

function App() {
  const [loginRedirect, setLoginRedirect] = useState('/');
  const dispatch = useDispatch();
  const sideMenuOpen = useSelector(getSideMenuOpen);
  const dropDownMenuOpen = useSelector(getDropDownMenuOpen);
  const themeMode = useSelector(getThemeMode);
  const { theme } = useTheme();
  const userStatus = useSelector(getUserStatus);
  const user = useSelector(getUser);
  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };
  const handleLogout = async () => {
    Swal.fire({
      title: 'Signing out...'
    });
    Swal.showLoading();
    logout();
    await logoutUser(user.token).then(() => {
      dispatch(reset_user());
      dispatch(resetMainSlice());
      dispatch(resetBaselineSlice());
    });
    Swal.close();

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Logout successful',
      showConfirmButton: false,
      timer: 1500
    });
    routeChange('/login');
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (userStatus === 'idle') {
      const token = getToken();
      if (token) {
        dispatch(fetchUserFromToken(signal));
      } else {
        if (window.location.search && window.location.pathname) {
          let tempPath = window.location.pathname + window.location.search;
          setLoginRedirect(tempPath);
        }
        routeChange('/login');
      }
    }
    return () => {
      controller.abort();
    };
  }, [userStatus]);

  return (
    <EsgianTheme app={APP_NAME} mode={theme.mode}>
      <Dashboard
        feedbackEmail={FEEDBACK_EMAIL}
        sideMenuContent={getSideMenuList(routeChange, sideMenuOpen, dropDownMenuOpen, dispatch)}
        appTitle={'Greenpact Live”'}
        app={APP_NAME}
        handleLogoutClick={handleLogout}
        hideMenu={!user}
        user={user}
        handleThemeChange={() => {
          dispatch(setThemeMode(!themeMode));
        }}
        themeMode={theme.mode}
        sideMenuOpen={!!sideMenuOpen}
        setSideMenuOpen={(val) => {
          dispatch(setSideMenuOpen(val));
        }}>
        <ToastContainer
          toastStyle={{ background: theme.palette.background.paper }}
          position="top-right"
          autoClose={3000}
          theme={theme.mode}
        />
        <Routes>
          <Route path="*" element={<Navigate to="/gpr-live" />} />
          <Route path="/" element={<Navigate to="/gpr-live" />} />
          <Route path="/login" exact element={<LoginPage loginRedirect={loginRedirect} />} />
          <Route
            exact
            path="/gpr-live"
            element={
              <PrivateRoute>
                <DashboardPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gpr-live/rig"
            element={
              <PrivateRoute>
                <GreenpactLivePage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gpr-live/baseline-comp"
            element={
              <PrivateRoute>
                <BaselineComparisonPage />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/test"
            element={
              <PrivateRoute>
                <TestPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Dashboard>
    </EsgianTheme>
  );
}

export default App;
