import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useUpdateSection } from '@hooks';
import { ArrowForwardIcon, Button, Paper, Stack, Typography } from '@esgian/esgianui';
import { SUB_SECTIONS } from '@constants';
import { PowerManagementChart } from '@components/Charts';
import { TextWithTooltipIcon } from '@components';

const getValue = (val) => {
  if (val === null || val === 'null') {
    return 0;
  }

  return parseFloat(val);
};
function PowerManagement({ loading, overviewData }) {
  const { updateMainPageSection } = useUpdateSection();

  const { series = [], categories = [] } = useMemo(() => {
    if (!overviewData) return {};
    const { clarifyData, numOfEngines } = overviewData;
    let cat = [];
    let data = [];
    for (let i = 1; i <= numOfEngines; i++) {
      cat.push(`Engine ${i}`);
      let v = Object.values(clarifyData[`Eng${i}Lavg`])?.filter((val) => getValue(val));

      let sum = v?.reduce((a, b) => a + getValue(b), 0);
      let avg = v.length ? sum / v.length : 0;
      data.push(avg.toFixed(2));
    }
    return { series: [{ data: data }], categories: cat };
  }, [overviewData]);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack>
        <TextWithTooltipIcon
          tooltipText={`Average engine load for each engine while online in the selected time period.`}
          label={<Typography variant={'h6'}>Power Management</Typography>}
        />
        <Typography variant={'body2'}>Average engine load in period</Typography>
      </Stack>
      <PowerManagementChart loading={loading} categories={categories} series={series} />
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button
          onClick={() => updateMainPageSection(SUB_SECTIONS.POWER_MGMT)}
          endIcon={<ArrowForwardIcon />}
          variant={'outlined'}>
          See power mgmt page
        </Button>
      </Stack>
    </Paper>
  );
}

PowerManagement.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

PowerManagement.defaultProps = {
  loading: false,
  overviewData: null
};

export default PowerManagement;
