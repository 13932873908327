import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack } from '@esgian/esgianui';
import KPIChangeDisplay from '@components/Display/KPIChangeDisplay/KPIChangeDisplay';
import { DISPLAY_UNIT } from '@constants';
import { getPercentDiff, getTotSum } from '@helpers';
import { useDisplayUnit } from '@hooks';
import { useSelector } from 'react-redux';
import { getBaselineDurationHours } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import { getDisplayUnit } from '@store/features';
import { getMainDurationHours } from '@store/features/filters/MainPageSlice/MainPageSlice';

function PeriodOverviewKpis({ mainData, baselineData, loading }) {
  const { valueWithUnit } = useDisplayUnit();
  const displayUnit = useSelector(getDisplayUnit);
  const mainDurationHours = useSelector(getMainDurationHours);
  const baselineDurationHours = useSelector(getBaselineDurationHours);
  const { getKpiDisplayText } = useDisplayUnit();

  const { mainTotals, baselineTotals } = useMemo(() => {
    if (!mainData || !baselineData) return {};
    let mainRig = {};
    let baselineRig = {};
    Object.values(DISPLAY_UNIT)?.forEach((unit) => {
      mainRig[unit] = getTotSum(mainData[`Rig${unit}`]);
      baselineRig[unit] = getTotSum(baselineData[`Rig${unit}`]);
    });
    return { mainTotals: mainRig, baselineTotals: baselineRig };
  }, [mainData, baselineData]);

  const kpis = useMemo(() => {
    if (!mainTotals || !baselineTotals) return {};
    let mainTot = mainTotals[displayUnit];
    let baselineTot = baselineTotals[displayUnit];
    let mainHour = mainTot / mainDurationHours;
    let baseHour = baselineTot / baselineDurationHours;
    let mainDay = (mainHour * mainDurationHours) / (mainDurationHours / 24);
    let baseDay = (baseHour * baselineDurationHours) / (baselineDurationHours / 24);

    let totDiff = mainTot - baselineTot;
    return {
      total: {
        ...valueWithUnit(totDiff, displayUnit, true),
        percent: getPercentDiff(mainTot, baselineTot)
      },
      dayAvg: {
        ...valueWithUnit(mainDay - baseDay, displayUnit, true),
        percent: getPercentDiff(mainDay, baseDay)
      },
      hourAvg: {
        ...valueWithUnit(mainHour - baseHour, displayUnit, true),
        percent: getPercentDiff(mainHour, baseHour)
      }
    };
  }, [displayUnit, mainTotals, baselineTotals]);

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.total?.percent}
        value={kpis.total?.value ?? null}
        unit={kpis.total?.title}
        title={getKpiDisplayText('Total')}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.dayAvg?.percent}
        value={kpis.dayAvg?.value ?? null}
        unit={kpis.dayAvg?.title}
        title={getKpiDisplayText('Average', 'day')}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.hourAvg?.percent}
        value={kpis.hourAvg?.value ?? null}
        unit={kpis.hourAvg?.title}
        title={getKpiDisplayText('Average', 'hour')}
      />
    </Stack>
  );
}

PeriodOverviewKpis.propTypes = {
  mainData: PropTypes.object,
  baselineData: PropTypes.object,
  loading: PropTypes.bool
};

PeriodOverviewKpis.defaultProps = {
  mainData: null,
  loading: false,
  baselineData: null
};

export default PeriodOverviewKpis;
