export const barHoverPlugin = {
  id: 'barHoverBackground',
  beforeDatasetsDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { top, width, height },
      scales: { x }
    } = chart;
    ctx.save();

    // Get the hoverValue specific to this chart instance
    const hoverValue = chart.$barHoverPluginHoverValue;

    const segment = width / (x.max + 1);
    ctx.fillStyle = options.backgroundColor;

    if (hoverValue !== undefined) {
      ctx.fillRect(x.getPixelForValue(hoverValue) - segment / 2, top, segment, height);
    }
  },
  afterEvent(chart, args, options) {
    const {
      scales: { x }
    } = chart;
    let hoverValue = undefined;
    // Store the hoverValue in a property attached to this chart instance
    if (args.inChartArea) {
      hoverValue = x.getValueForPixel(args.event.x);
    }
    chart.$barHoverPluginHoverValue = hoverValue;

    args.changed = true;
    if (options.disabled) return;
    options.syncRefs?.forEach((chartRef) => {
      if (chartRef.current) {
        let secondCtx = chartRef.current.ctx;
        const {
          chartArea: { top: secTop, width: secWidth, height: secHeight },
          scales: { x: secX }
        } = chartRef.current;

        chartRef.current.render();
        if (hoverValue !== undefined) {
          const segment2 = secWidth / (secX.max + 1);
          secondCtx.fillRect(
            secX.getPixelForValue(hoverValue) - segment2 / 2,
            secTop,
            segment2,
            secHeight
          );
        }
      }
    });
  }
};
