/**
 * Plugin that removes the tooltip from synced charts when the mouse leaves the chart
 * @param syncRefs array of references to synced charts
 * */
export const syncTooltipHiderPlugin = {
  id: 'syncTooltipHiderPlugin', // Unique ID for the plugin
  beforeEvent(chart, args, options) {
    const event = args.event;
    if (event && event.type === 'mouseout') {
      options.syncRefs?.forEach(({ current }) => {
        const tooltip = current?.tooltip;
        if (tooltip) {
          tooltip.setActiveElements([]); // Clear active elements to hide tooltips
          current.update(); // Ensure the chart is updated
        }
      });
    }
  }
};
