import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Skeleton,
  Stack,
  TrendingDownIcon,
  TrendingUpIcon,
  Typography
} from '@esgian/esgianui';
import { formatNumberReadable, parseNumberToSignificant } from '@helpers';

function KpiChangeDisplay({ value, percentChange, title, invert, unit, loading }) {
  const getColor = (value) => {
    if (value >= 0) {
      if (invert) {
        return 'success';
      }
      return 'error';
    } else {
      if (invert) {
        return 'error';
      }
      return 'success';
    }
  };

  const getArrow = () => {
    if (percentChange === 'N/A') {
      return <></>;
    }
    if (value >= 0) {
      return <TrendingUpIcon color={getColor(value)} fontSize={'small'} />;
    }
    return <TrendingDownIcon color={getColor(value)} fontSize={'small'} />;
  };

  if (loading) {
    return (
      <Paper sx={{ height: '80px', width: 'max-content' }}>
        <Stack alignItems={'center'}>
          <Stack direction={'row'} spacing={0.5} sx={{ height: '40px' }}>
            <Skeleton variant={'rounded'} height={'38px'} width={'75px'} />
            <Skeleton
              sx={{ alignSelf: 'flex-end' }}
              variant={'rounded'}
              height={'1.25em'}
              width={'1.7em'}
            />
          </Stack>
          {typeof title === 'string' ? (
            <Typography variant={'caption'} color={'text.disabled'}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Stack>
      </Paper>
    );
  }
  return (
    <Paper sx={{ height: '80px', width: '320px' }}>
      <Stack alignItems={'center'}>
        <Stack direction={'row'} spacing={1} alignItems="baseline">
          <Typography variant={'h4'}>{`${value >= 0 ? `+` : '-'}${formatNumberReadable(
            parseNumberToSignificant(Math.abs(value))
          )}`}</Typography>
          <Typography variant={'caption'} color={'text.disabled'}>
            {unit}
          </Typography>
          <Stack direction={'row'} sx={{ alignSelf: 'self-end', pb: 0.5 }}>
            <Typography
              variant={'body2'}
              sx={{
                color: ({ palette }) => {
                  if (percentChange === 'N/A') {
                    return palette.text.disabled;
                  }
                  return palette[getColor(value)].main;
                }
              }}>
              {console.log(percentChange)}
              {percentChange !== 'N/A' && percentChange !== 'NaN'
                ? `${Math.abs(percentChange)}%`
                : 'N/A'}
            </Typography>
            {getArrow()}
          </Stack>
        </Stack>
        {typeof title === 'string' ? (
          <Typography variant={'caption'} color={'text.disabled'}>
            {title}
          </Typography>
        ) : (
          title
        )}
      </Stack>
    </Paper>
  );
}

KpiChangeDisplay.propTypes = {
  value: PropTypes.number,
  percentChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  invert: PropTypes.bool,
  loading: PropTypes.bool,
  unit: PropTypes.string
};

KpiChangeDisplay.defaultProps = {
  value: 0,
  percentChange: 0,
  title: '',
  invert: false,
  loading: false,
  unit: '-'
};

export default KpiChangeDisplay;
