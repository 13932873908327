export const timeSeries1 = [
  { x: 1625097600000, y: 45 },
  { x: 1625184000000, y: 120 },
  { x: 1625270400000, y: 80 },
  { x: 1625356800000, y: 150 },
  { x: 1625443200000, y: 95 },
  { x: 1625529600000, y: 60 },
  { x: 1625616000000, y: 200 },
  { x: 1625702400000, y: 30 },
  { x: 1625788800000, y: 175 },
  { x: 1625875200000, y: 110 }
];
export const timeSeries2 = [
  { x: 1625097600000, y: 180 },
  { x: 1625184000000, y: 75 },
  { x: 1625270400000, y: 130 },
  { x: 1625356800000, y: 50 },
  { x: 1625443200000, y: 160 },
  { x: 1625529600000, y: 90 },
  { x: 1625616000000, y: 140 },
  { x: 1625702400000, y: 25 },
  { x: 1625788800000, y: 190 },
  { x: 1625875200000, y: 105 }
];
