import React from 'react';
import { DashboardIcon } from '@esgian/esgianui';
import { setSideMenuItemOpen } from '@store/features';

export const getSideMenuList = (routeChange, sideMenuOpen, dropDownMenuOpen, dispatch) => {
  return [
    {
      key: 'dashboard',
      id: 'dashboard',
      icon: <DashboardIcon color={'inherit'} />,
      text: 'Dashboard',
      path: '/greenpact-live',
      onClick: () => {
        routeChange('/greenpact-live');
      }
    }
  ];
};
