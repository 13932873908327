import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import { UTC_TIMESTAMP, WARNINGS_TYPE } from '@constants';
import { useTimezone } from '@hooks/useTimezone';
import CanvasTimeSeriesChart from '@components/Charts/CanvasTimeSeriesChart/CanvasTimeSeriesChart';
import { Stack, Typography } from '@esgian/esgianui';
import { useTheme } from '@hooks';
import { getRgbaColor } from '@components/Charts/ChartJsColorHelpers';
import { getUnixTimeStamp, getValue } from '@helpers';
let hpuNames = [
  'Actual HPU power available',
  'Actual HPU peak power',
  'Optimal HPU power available',
  'Optimal HPU power',
  'Actual online HPU',
  'Optimal online HPU'
];
let engNames = [
  'Power peak',
  'Average power produced',
  'Total power available',
  'Optimal power available',
  'Actual engines online',
  'Optimal engines online'
];

const getBackgroundColor = (value) => {
  if (value.opacity) {
    return getRgbaColor(value.color, value.opacity);
  }
  return 'rgba(0, 0, 0, 0.1)';
};

function WarningPowerDetailsChart({
  detailsData,
  rowData,
  loading,
  primaryTitle,
  secondaryTitle,
  unit,
  powerChartRef,
  onlineChartRef,
  powerSyncRefs,
  onlineSyncRefs
}) {
  const { selectedTimeZone } = useTimezone();
  const { theme } = useTheme();
  const {
    palette: {
      charts: { sixColorsLine, twoColorsContrast }
    }
  } = theme;
  let chartColors =
    rowData?.type === WARNINGS_TYPE.TooManyHPU
      ? [
          { order: 2, color: sixColorsLine[3], opacity: undefined },
          { order: 3, color: sixColorsLine[1], opacity: 0.4 },
          { order: 1, color: sixColorsLine[5], opacity: undefined },
          { order: 4, color: sixColorsLine[2], opacity: 0.4 }
        ]
      : [
          { order: 1, color: sixColorsLine[3], opacity: undefined },
          { order: 2, color: sixColorsLine[1], opacity: 0.4 },
          { order: 4, color: sixColorsLine[4], opacity: 0.4 },
          { order: 3, color: sixColorsLine[5], opacity: 0.4 }
        ];

  const { mainSeries = [], secondarySeries = [] } = useMemo(() => {
    if (!detailsData) return {};
    let isHPU = rowData.type === WARNINGS_TYPE.TooManyHPU;
    let startDate = moment.tz(rowData.startDate, selectedTimeZone).subtract(30, 'minute');
    let endDate = moment.tz(rowData.endDate, selectedTimeZone).add(30, 'minutes');
    let diff = endDate.diff(startDate, 'minute');
    const names = isHPU ? hpuNames : engNames;
    const { peakPower, avgPowerProd, totPowerAvailable, optPowerAvailable, engOn, optEngOn } =
      detailsData;
    let main = [
      {
        order: chartColors[0].order,
        spanGaps: false,
        label: names[0],
        borderColor: chartColors[0].color,
        type: 'line',
        data: Object.keys(peakPower)?.map((key) => {
          let value = getValue(peakPower[key], false);
          return { x: getUnixTimeStamp(key).valueOf(), y: value };
        })
      },
      {
        order: chartColors[1].order,
        spanGaps: false,
        label: names[1],
        backgroundColor: getBackgroundColor(chartColors[1]),
        type: 'line',
        fill: 'origin',
        borderColor: chartColors[1].color,
        data: Object.keys(avgPowerProd)?.map((key) => {
          let value = getValue(avgPowerProd[key], false);
          return { x: getUnixTimeStamp(key).valueOf(), y: value };
        })
      },
      {
        order: chartColors[2].order,
        spanGaps: false,
        type: 'line',
        label: names[2],
        borderColor: chartColors[2].color,
        fill: isHPU ? false : 'origin',
        backgroundColor: getBackgroundColor(chartColors[2]),
        data: Object.keys(totPowerAvailable)?.map((key, i) => {
          let value = getValue(totPowerAvailable[key], false);
          if (isHPU && (i <= 29 || i >= diff - 29)) {
            return { x: getUnixTimeStamp(key).valueOf(), y: null };
          }

          return { x: getUnixTimeStamp(key).valueOf(), y: value };
        })
      },
      {
        order: chartColors[3].order,
        label: names[3],
        spanGaps: false,
        type: 'line',
        borderColor: chartColors[3].color,
        fill: 'origin',
        backgroundColor: getBackgroundColor(chartColors[3]),
        data: Object.keys(optPowerAvailable)?.map((key, i) => {
          let value = getValue(optPowerAvailable[key], false);
          if (i <= 29 || i >= diff - 29) {
            return { x: getUnixTimeStamp(key).valueOf(), y: null };
          }

          return { x: getUnixTimeStamp(key).valueOf(), y: value };
        })
      }
    ];
    const sec = [
      {
        order: 2,
        label: names[4],
        borderColor: twoColorsContrast[0],
        spanGaps: false,
        fill: 'origin',
        backgroundColor: getBackgroundColor({ color: twoColorsContrast[0], opacity: 0.4 }),
        data: Object.keys(engOn)?.map((key) => {
          let value = getValue(engOn[key], false);
          return { x: getUnixTimeStamp(key).valueOf(), y: value };
        })
      },
      {
        order: 1,
        label: names[5],
        spanGaps: false,
        borderColor: twoColorsContrast[1],
        fill: 'origin',
        backgroundColor: getBackgroundColor({ color: twoColorsContrast[1], opacity: 0.4 }),
        data: Object.keys(optEngOn)?.map((key, i) => {
          let value = getValue(optEngOn[key], false);
          if (i <= 29 || i >= diff - 29) {
            return { x: getUnixTimeStamp(key).valueOf(), y: null };
          }

          return { x: getUnixTimeStamp(key).valueOf(), y: value };
        })
      }
    ];

    if (!isHPU) {
      main = [main[2], main[1], main[0], main[3]];
    }
    return {
      mainSeries: main,
      secondarySeries: sec
    };
  }, [detailsData, rowData]);

  return (
    <Stack spacing={2}>
      <Stack>
        {primaryTitle && (
          <Typography variant={'subtitle1'} bold sx={{ pb: 2 }}>
            {primaryTitle}
          </Typography>
        )}
        <CanvasTimeSeriesChart
          loading={loading}
          unit={unit}
          height={'175px'}
          syncRefs={powerSyncRefs}
          chartRef={powerChartRef}
          series={mainSeries}
          id={'first-chart'}
        />
      </Stack>
      <Stack>
        <Typography variant={'subtitle1'} bold sx={{ pb: 2 }}>
          {secondaryTitle}
        </Typography>
        <CanvasTimeSeriesChart
          maxY={detailsData?.numOfEngines ?? 5}
          loading={loading}
          height={'175px'}
          chartRef={onlineChartRef}
          syncRefs={onlineSyncRefs}
          series={secondarySeries}
          id={'sec-chart'}
        />
      </Stack>
    </Stack>
  );
}

WarningPowerDetailsChart.propTypes = {
  detailsData: PropTypes.object,
  rowData: PropTypes.object,
  unit: PropTypes.string,
  loading: PropTypes.bool,
  primaryTitle: PropTypes.object,
  secondaryTitle: PropTypes.object,
  powerChartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
  onlineChartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
    .isRequired,
  powerSyncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ).isRequired,
  onlineSyncRefs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
  ).isRequired
};

WarningPowerDetailsChart.defaultProps = {
  detailsData: null,
  primaryTitle: null,
  secondaryTitle: null,
  unit: '',
  rowData: null,
  loading: false
};

export default WarningPowerDetailsChart;
