import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Box, EsgianTheme, Paper, Stack, Typography } from '@esgian/esgianui';
import { APP_NAME } from '@constants';
import moment from 'moment-timezone';
import ReactDOMServer from 'react-dom/server';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getSideMenuOpen } from '@store/features';

const getOptions = (theme, endDate) => {
  const {
    palette: {
      mode,
      charts: { background, heatmapChartColors },
      background: { paper },
      tooltip: { tooltipBackground }
    }
  } = theme;
  return {
    chart: {
      animations: {
        enabled: false
      },
      // offsetY: -20,
      toolbar: {
        show: false
      },
      height: 350,
      background: background,
      type: 'heatmap',
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(',')
    },
    grid: {
      column: {
        opacity: 0
      },
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    theme: {
      mode: mode
    },
    xaxis: {
      tooltip: {
        enabled: false
      },
      crosshairs: {
        show: false
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      labels: { offsetY: 5, show: true },
      categories: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
      ],
      position: 'top'
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'round',
      colors: [paper],
      width: 4,
      dashArray: 0
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '10px'
        }
      }
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        let date = moment
          .utc(endDate)
          .startOf('day')
          .subtract(seriesIndex, 'days')
          .add(dataPointIndex, 'hours')
          .format('DD/MMM HH:mm');
        return ReactDOMServer.renderToString(
          <EsgianTheme app={APP_NAME} mode={mode}>
            <Paper
              variant={'outlined'}
              style={{
                padding: '8px 8px 8px 8px',
                background: tooltipBackground,
                borderWidth: 0,
                minWidth: '200px'
              }}>
              <Stack spacing={2}>
                <Typography style={{ margin: 0, fontWeight: 600 }} variant={'body2'} bold>
                  {date}
                </Typography>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'body2'}>Warnings:</Typography>
                  <Typography variant={'body2'}>{series[seriesIndex][dataPointIndex]}</Typography>
                </Stack>
              </Stack>
            </Paper>
          </EsgianTheme>
        );
      }
    },
    plotOptions: {
      heatmap: {
        radius: 3,
        enableShades: false,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: heatmapChartColors?.map((color, index) => {
            return {
              from: index,
              to: index + 1,
              color: heatmapChartColors[index]
            };
          })
        }
      }
    }
  };
};

function HeatMap({ series, loading }) {
  const { theme } = useTheme();
  const { endDate } = useSelector(getMainPageSlice);
  const sideMenuOpen = useSelector(getSideMenuOpen);
  const [reloadKey, setReloadKey] = useState(0);

  const options = useMemo(() => {
    return getOptions(theme, endDate);
  }, [theme, endDate]);

  useEffect(() => {
    setReloadKey((prev) => prev + 1);
  }, [sideMenuOpen]);

  return (
    <Box
      sx={{
        height: '400px',
        overflowX: 'auto',
        '::-webkit-scrollbar': {
          width: '7px'
        },

        /* Track */
        '::-webkit-scrollbar-track': {
          borderRadius: '10px',
          background: ({ palette }) => palette.neutral.neutral01
        },

        /* Handle */
        ' ::-webkit-scrollbar-thumb': {
          background: (theme) => theme.palette.primary.main,
          borderRadius: '10px'
        }
      }}>
      <ApexChart
        newLoadingAnimation
        loading={loading}
        width={'98%'}
        key={reloadKey}
        height={series.length > 17 ? 23 * series.length : 375}
        options={options}
        type={'heatmap'}
        data={series}
      />
    </Box>
  );
}

HeatMap.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

HeatMap.defaultProps = {
  series: [],
  loading: false
};

export default HeatMap;
