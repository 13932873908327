import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SimpleBarChart from '../SimpleBarChart';
import KeyValueTooltip from '@components/Charts/KeyValueTooltip';
import { useDisplayUnit } from '@hooks';

function OperationsChart({ series, loading, primaryXAxisLabel }) {
  const { displayUnit } = useDisplayUnit();
  const categories = useMemo(() => {
    return ['Hotel & Utilities', 'Drilling Equipment'];
  }, []);
  return (
    <SimpleBarChart
      primaryXAxisLabel={primaryXAxisLabel}
      primaryTitleOffsetY={330}
      loading={loading}
      vertical
      categories={categories}
      series={series}
      customTooltipContent={(series, dataPointIndex) => (
        <KeyValueTooltip
          displayUnit={displayUnit}
          label={categories[dataPointIndex]}
          value={series[0][dataPointIndex]}
          unit={primaryXAxisLabel}
        />
      )}
    />
  );
}
OperationsChart.propTypes = {
  loading: PropTypes.bool,
  primaryXAxisLabel: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object)
};
OperationsChart.defaultProps = {
  loading: false,
  primaryXAxisLabel: '',
  series: []
};
export default OperationsChart;
