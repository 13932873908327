/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import { PercentageLoadPerEngineChart } from '@components/Charts';
import EnginePercentageLoadChart from '../../Charts/EnginePercentageLoadChart';
import PowerDetailsWrapper from '@components/Sections/PowerMgmtSection/PowerDetailsWrapper';
import { useTimezone } from '@hooks/useTimezone';
import { useMainPageQueries } from '@hooks/usePageQueries/useMainPageQueries';
import { TextWithTooltipIcon } from '@components';

function PowerMgmtSection() {
  const [selectedDate, setSelectedDate] = useState(null);
  const { selectedTimeZone } = useTimezone();
  const { rigPowerMgmtQuery } = useMainPageQueries();

  useEffect(() => {
    if (!rigPowerMgmtQuery.data) return;
    const {
      clarifyData: { EngOn = {} }
    } = rigPowerMgmtQuery.data;
    setSelectedDate({
      index: 0,
      date: Object.keys(EngOn || {})?.[0],
      isLast: false
    });
  }, [rigPowerMgmtQuery.data, selectedTimeZone]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3.5}>
        <Paper>
          <Stack sx={{ pl: 2, pt: 2 }}>
            <TextWithTooltipIcon
              tooltipText={`Average engine load for each engine in the selected time period while running and the average SFOC value.
SFOC is the Specific Fuel Oil Consumption. The SFOC value indicates how much fuel is used to produce 1 kwh.
This value is calculated from the engines fuel consumption divided by the energy produced.
In general engine load 80-90% will give the lowest (best) SFOC value.`}
              label={<Typography variant={'h6'}>Engines summary</Typography>}
            />
            <Typography variant={'body2'}>Average engine load and SFOC in the period</Typography>
          </Stack>
          <PercentageLoadPerEngineChart
            loading={rigPowerMgmtQuery.isFetching}
            powerMgmtData={rigPowerMgmtQuery.data}
          />
        </Paper>
      </Grid>
      <Grid item xs={8.5}>
        <Paper sx={{ pl: 2, pt: 2, pr: 2 }}>
          <Stack spacing={1}>
            <Stack>
              <TextWithTooltipIcon
                tooltipText={`Average engine load for all online engines per day, and the averafe number of engines online per day. Clicking on one of the bars will reveal the details per hour for the day before, selected day and the next dat in the graphs below.`}
                label={<Typography variant={'h6'}>Engines overview</Typography>}
              />
              <Typography variant={'body2'}>Daily average engine load</Typography>
            </Stack>
            <EnginePercentageLoadChart
              selectedDate={selectedDate}
              loading={rigPowerMgmtQuery.isFetching}
              powerMgmtData={rigPowerMgmtQuery.data}
              setSelectedDate={setSelectedDate}
            />
          </Stack>
          <PowerDetailsWrapper loading={rigPowerMgmtQuery.isFetching} selectedDate={selectedDate} />
        </Paper>
      </Grid>
    </Grid>
  );
}
PowerMgmtSection.propTypes = {};

PowerMgmtSection.defaultProps = {};

export default PowerMgmtSection;
