import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@esgian/esgianui';
import PeriodicOverviewChart from '@components/Charts/PeriodicOverviewChart/PeriodicOverviewChart';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';
import { useSeriesBuilder, useDisplayUnit, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getUnixTimeStamp, getValue } from '@helpers';
import { DISPLAY_UNIT } from '@constants';

function PeriodicOverview({ loading, overviewData }) {
  const displayUnit = useSelector(getDisplayUnit);

  const { getUnitTitle } = useDisplayUnit();
  const { getSeriesData } = useSeriesBuilder();
  const { theme } = useTheme();
  const {
    palette: {
      charts: { twoColorsContrast }
    }
  } = theme;

  const { series = [], yAxisTitle = '' } = useMemo(() => {
    if (!overviewData) return {};
    const { clarifyData } = overviewData;
    let data = clarifyData[`Rig${displayUnit}`];

    const { max, defaultArray, convertedArray } = getSeriesData(data, displayUnit);
    const { title, converted } = getUnitTitle(max);
    const dataValuesArray = converted ? convertedArray : defaultArray;
    const accumulated = [];
    const consumption = [];
    const dates = Object.keys(data).map((key) => getUnixTimeStamp(key).valueOf());
    let consumptionSeriesName = 'Energy production';
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        consumptionSeriesName = 'CO2e emissions ';
        break;
      case DISPLAY_UNIT.NOX:
        consumptionSeriesName = 'NOx emissions';
        break;
      case DISPLAY_UNIT.FUEL:
        consumptionSeriesName = 'Fuel consumption';
        break;
    }
    dataValuesArray.forEach((val, i) => {
      let value = getValue(val);
      consumption.push({ x: dates[i], y: value });
      if (i === 0) {
        accumulated.push({ x: dates[i], y: value });
      } else {
        accumulated.push({ x: dates[i], y: accumulated[i - 1].y + value });
      }
    });
    return {
      yAxisTitle: title,
      series: [
        {
          label: consumptionSeriesName,
          yAxisID: 'y',
          borderColor: twoColorsContrast[0],
          type: 'bar',
          order: 2,
          backgroundColor: twoColorsContrast[0],
          borderSkipped: true,
          data: consumption
        },
        {
          label: 'Accumulated',
          borderColor: twoColorsContrast[1],
          yAxisID: 'y1',
          order: 1,
          type: 'line',
          data: accumulated
        }
      ]
    };
  }, [overviewData, displayUnit, theme]);

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextWithTooltipIcon
            tooltipText={
              'Daily and accumulated production, consumption or emissions in the selected time period.'
            }
            label={<Typography variant={'h6'}>Periodic overview</Typography>}
          />
        </Grid>
        <Grid item xs={12}>
          <PeriodicOverviewChart yAxisTitle={yAxisTitle} series={series} loading={loading} />
        </Grid>
      </Grid>
    </Paper>
  );
}

PeriodicOverview.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

PeriodicOverview.defaultProps = {
  loading: false,
  overviewData: null
};

export default PeriodicOverview;
