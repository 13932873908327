import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Typography } from '@esgian/esgianui';
import { OperationsChart } from '@components/Charts';
import { DISPLAY_UNIT } from '@constants';
import { useDisplayUnit } from '@hooks';
import { useSelector } from 'react-redux';
import { getDisplayUnit } from '@store/features';
import { getValue, parseNumberToSignificant } from '@helpers';
import TextWithTooltipIcon from '@components/Display/TextWithTooltipIcon';

function Operations({ loading, overviewData }) {
  const { getUnitTitle, convertUnitValue } = useDisplayUnit();
  const displayUnit = useSelector(getDisplayUnit);

  const { primaryXAxisLabel = '', series = [] } = useMemo(() => {
    if (!overviewData) return {};
    const {
      clarifyData: {
        AccomEnergy,
        AccomCO2,
        AccomFuel,
        AccomNOx,
        DrillingEnergy,
        DrillingCO2,
        DrillingFuel,
        DrillingNOx
      }
    } = overviewData;
    let accommodation = AccomCO2;
    let drilling = DrillingCO2;

    if (displayUnit === DISPLAY_UNIT.ENERGY) {
      accommodation = AccomEnergy;
      drilling = DrillingEnergy;
    }
    if (displayUnit === DISPLAY_UNIT.FUEL) {
      accommodation = AccomFuel;
      drilling = DrillingFuel;
    }
    if (displayUnit === DISPLAY_UNIT.NOX) {
      accommodation = AccomNOx;
      drilling = DrillingNOx;
    }
    accommodation = Object.values(accommodation).reduce((a, b) => a + getValue(b), 0);
    drilling = Object.values(drilling).reduce((a, b) => a + getValue(b), 0);

    let max = accommodation > drilling ? accommodation : drilling;
    const { title, converted } = getUnitTitle(max);
    accommodation = converted
      ? convertUnitValue(accommodation, displayUnit)
      : parseNumberToSignificant(accommodation);
    drilling = converted
      ? convertUnitValue(drilling, displayUnit)
      : parseNumberToSignificant(drilling);

    return {
      primaryXAxisLabel: title,
      series: [
        {
          name: '',
          type: 'column',
          data: [accommodation, drilling]
        }
      ]
    };
  }, [overviewData, displayUnit]);

  const getSubTitle = useMemo(() => {
    switch (displayUnit) {
      case DISPLAY_UNIT.CO2:
        return 'Total CO2e emissions in period';
      case DISPLAY_UNIT.NOX:
        return 'Total NOx emissions in period';
      case DISPLAY_UNIT.FUEL:
        return 'Total fuel consumption in period';
      case DISPLAY_UNIT.ENERGY:
        return 'Total energy consumption in period';
    }
  }, [displayUnit]);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack spacing={2}>
        <Stack>
          <TextWithTooltipIcon
            tooltipText={
              'Total consumption or emissions in the selected time period for each system category.'
            }
            label={<Typography variant={'h6'}>System summary</Typography>}
          />
          <Typography variant={'body2'}>{getSubTitle}</Typography>
        </Stack>
        <Stack>
          <OperationsChart
            primaryXAxisLabel={primaryXAxisLabel}
            series={series}
            loading={loading}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

Operations.propTypes = {
  loading: PropTypes.bool,
  overviewData: PropTypes.object
};

Operations.defaultProps = {
  loading: false,
  overviewData: null
};

export default Operations;
